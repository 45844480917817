import { Button, Container, Divider, FormControl, FormLabel, HStack, Heading, Image, Input, Stack, Text, Textarea } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { blog } from '../../../Data/Blog'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from "moment";
import { FaFacebook, FaLongArrowAltLeft, FaPinterest, FaTwitter } from 'react-icons/fa';
import parse from 'html-react-parser';
const themeColor = '#0174BE'

const SingleBlogPage = () => {
     const params = useParams()
     const navigate = useNavigate()
     // const [dataBlog, setDataBlog] = useState({})

     const location = useLocation()
     const dataBlog = location.state

     // const fetchSingleDataBlog = async () => {
     //      const res = await blog.find((x) => x.title === (decodeURIComponent(params.title).replace(/-/g, ' ')))
     //      setDataBlog(res)
     // }
     const karla = {
          fontFamily: 'Karla',
          fontWeight: 400
     }
     const ovo = {
          fontFamily: 'Ovo',
          fontWeight: 400,
     }

     // useEffect(() => {
     //      fetchSingleDataBlog()
     // }, [])

     return (
          <Container maxW={'container.lg'} mb={10}>
               <Stack gap={10}>
                    <Stack>
                         <HStack textAlign={'center'} justify={'center'}>
                              {dataBlog?.tags?.map((y, id) => (
                                   <HStack textAlign={'center'} justify={'center'} wrap={'wrap'} key={id}>
                                        <Text textTransform={'uppercase'} fontSize={'sm'} style={karla} key={id}>{y}</Text>
                                        <Text mt={'-2'}>.</Text>
                                   </HStack>
                              ))}
                              <Text textTransform={'uppercase'} fontSize={'sm'} style={karla} >{moment(dataBlog?.createdAt).format('ll')}</Text>
                         </HStack>
                         <Heading textAlign={'center'} style={ovo}>{dataBlog?.title}</Heading>
                    </Stack>
                    <Stack alignItems={"center"} justifyContent="center">
                         <Image src={dataBlog?.image} w={"700px"} objectFit={'cover'} />
                    </Stack>
                    <Stack style={karla} textAlign={'justify'} >
                         {dataBlog && dataBlog?.post &&
                              parse(dataBlog?.post)
                         }
                    </Stack>
                    <HStack gap={10}>
                         <Button leftIcon={<FaFacebook />} style={karla} variant={'link'} color={'black'}>Share</Button>
                         <Button leftIcon={<FaTwitter />} style={karla} variant={'link'} color={'black'}>Tweet</Button>
                         <Button leftIcon={<FaPinterest />} style={karla} variant={'link'} color={'black'}>Pin it</Button>

                    </HStack>
                    <Divider />
                    <Stack gap={5}>
                         <Heading fontSize={'3xl'} style={ovo}>Leave a comment</Heading>
                         <HStack style={karla}>
                              <FormControl>
                                   <FormLabel>Name</FormLabel>
                                   <Input rounded={0} />
                              </FormControl>
                              <FormControl>
                                   <FormLabel>Email</FormLabel>
                                   <Input rounded={0} />
                              </FormControl>
                         </HStack>
                         <FormControl>
                              <FormLabel style={karla}>Message</FormLabel>
                              <Textarea rounded={0} />
                         </FormControl>
                         <Text style={karla}>Please note, comments must be approved before they are published</Text>
                         <Button rounded={0} bgColor={themeColor} color='white' w={'fit-content'} style={ovo} colorScheme='none' _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }} >Post a comment</Button>
                         <HStack wrap={'wrap'} style={karla}>
                              <a>This site is protected by reCAPTCHA and the Google {' '}
                                   <a>Privacy Policy {' '}</a>
                                   <a>and{' '}</a>
                                   <a>Terms of Service{' '}</a>
                                   <a>apply</a>
                              </a>

                         </HStack>
                    </Stack>
                    <Divider />
                    <Button justifyContent={'center'} display={'flex'} alignItems={'center'} style={karla} leftIcon={<FaLongArrowAltLeft />} color={'black'} variant={'link'} onClick={() => navigate('/blogs')}>Back to Journal</Button>
               </Stack>
          </Container>
     )
}

export default SingleBlogPage