import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../Config/firebase";
import { create } from "zustand";

export const UseAuthStore = create((set, get) => ({
  isLoggedIn: false,
  user: {},
  tokens: {},
  isLoading: false,
  currentCompany: "",
  currentProject: "",
  authFirebaseToken: "",
  uid: "",

  setTokens: (data) => {
    set({ tokens: data });
  },

  setUid: (data) => {
    set({ uid: data });
  },

  setAuthFirebaseToken: (data) => {
    set({ authFirebaseToken: data });
  },

  setUser: (data) => {
    set({ user: data });
  },

  setCurrentCompany: (data) => {
    set({ currentCompany: data });
  },

  setCurrentProject: (data) => {
    set({ currentProject: data });
  },

  setIsLoggedIn: (data) => {
    set({ isLoggedIn: data });
  },

  setIsLoading: (data) => {
    set({ isLoading: data });
  },
}));
