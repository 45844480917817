import { AspectRatio, Box, Button, HStack, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { blog } from '../../Data/Blog'
import moment from "moment";
import parse from 'html-react-parser';
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import { useNavigate } from 'react-router-dom';
const ovo = {
  fontFamily: 'Ovo',
  fontWeight: 400,
}
const karla = {
  fontFamily: 'Karla',
  fontWeight: 400
}
const themeColor = '#0174BE'
const BlogsPage = () => {

  const [dataArtikel, setDataArtikel] = useState([])

  const navigate = useNavigate()


  const getDataArtikel = async () => {
    const conditions = [
      { field: "active", operator: "==", value: true },
    ];
    const sortBy = { field: "createdAt", direction: "desc" };
    const limitValue = 10;

    try {
      const res = await getCollectionFirebase(
        "artikel",
        conditions,
        sortBy,
        limitValue
      );
      setDataArtikel(res);
    } catch (error) {
      console.log(error, 'ini error')
    }
  }

  useEffect(() => {
    getDataArtikel()

    return () => {
      setDataArtikel([])
    }
  }, [])

  return (
    <Stack mb={10}>
      <Heading style={ovo} fontSize={'3xl'} textAlign={'center'} mb={[5, 5, 5, 5, 10, 10]}>Artikel</Heading>
      <Stack columns={1} gap={10}>
        {dataArtikel.length > 0 && dataArtikel?.map((x, i) => (
          <HStack key={i} col gap={10} flexDirection={{ base: 'column', md: i % 2 === 0 ? 'row' : 'row-reverse' }}>
            <Image w={{ base: '100%', md: '50%' }} h={{ base: 'none', md: 450 }} src={x.image} aspectRatio={16 / 9} objectFit={'cover'} />

            <Stack gap={5} w={["300px", "auto", "auto"]} >
              <Stack gap={2}>
                <Text letterSpacing={'0.2em'} textTransform={'uppercase'} fontSize={'sm'} style={karla} >{moment(x?.createdAt.seconds * 1000).format('ll')}</Text>
                <Heading fontSize={['lg', 'xl', '3xl']} style={ovo}>{x?.title}</Heading>
              </Stack>
              <Text noOfLines={5} overflow="hidden" style={karla} textAlign={'justify'} >
                {x?.post &&
                  parse(x?.post)
                }
              </Text>
              <Button onClick={() => navigate(`/blogs/${x.id}`, {state: x})} color={'white'} bgColor={themeColor} rounded={0} colorScheme='none' style={ovo} size={'lg'} w={'fit-content'}>Continue reading</Button>
            </Stack>
          </HStack>
        ))}
      </Stack>
    </Stack>
  )
}

export default BlogsPage