import React, { Fragment, useEffect, useState } from 'react'
import {
     Accordion,
     AccordionButton,
     AccordionIcon,
     AccordionItem,
     AccordionPanel,
     Box,
     Button,
     Checkbox,
     Circle,
     Flex,
     FormControl,
     FormLabel,
     HStack,
     Heading,
     Icon,
     Image,
     Input,
     InputGroup,
     InputRightElement,
     Radio,
     RadioGroup,
     Select,
     SimpleGrid,
     Stack,
     StackDivider,
     Text,
     Tooltip,
     useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import { country, province } from "../../Data/Country";
import { shipping } from "../../Data/Shipping";
import { formatFrice } from "../../Utils/numberUtil";
import { dataCheckout } from '../../Data/Collections';
import { dataUser } from '../../Data/DataUser';
import { TbDeviceDesktopShare } from 'react-icons/tb';
import HeaderCheckoutComponent from './Components/HeaderCheckoutComponent';
import FooterCheckoutComponent from './Components/FooterCheckoutComponent';

const themeColor = '#0174BE'
const textMuted = 'rgba(0,0,0,0.56)'

const ListOrderComponent = ({ shippingPrice, setTotalPrice }) => {
     const [isDesktop] = useMediaQuery('(min-width: 1280px)')
     let price = 0

     useEffect(() => {
          if (!isDesktop) {
               setTotalPrice(price + shippingPrice)
          }
          return () => {
          }
     }, [])
     return (
          <Stack
               bgColor={"#FAF2E1"}
               borderLeft={isDesktop && "1px solid rgb(224,210,177)"}
               borderTop={!isDesktop && "1px solid rgb(224,210,177)"}
               pos={"sticky"}
               top={0}
               p={8}
               gap={5}
          >
               {dataCheckout.map((x, i) => {
                    price += x.price
                    return (
                         <Flex gap={5} key={i}>
                              <Box pos={'relative'}>
                                   <Circle pos={'absolute'} right={'-2'} top={'-2'} bgColor={'rgba(0,0,0,0.56)'} w={5} color={'white'} fontSize={'sm'}><Text>{x.quantity}</Text></Circle>
                                   <Image src={x.thumbnail} aspectRatio={43 / 64} w={'86px'} />
                              </Box>
                              <Stack w='full'>
                                   <Text>{x.name}</Text>
                                   <Flex justify={'space-between'} align={'center'}>
                                        <Text textTransform={'uppercase'}>{x.size ? x.size : 'All Size'}</Text>
                                        <Text>IDR {formatFrice(x.quantity * x.price)}.00</Text>
                                   </Flex>
                                   {x.quantity > 1 &&
                                        <Text>IDR {formatFrice(x.price)}.00/ea</Text>
                                   }
                              </Stack>
                         </Flex>
                    )
               })}
               <HStack>
                    <Input placeholder='Discount code or gift card' bgColor={'white'} />
                    <Button bgColor={'rgb(250,242,225)'} border={'1px solid rgb(224,210,177)'} color={'rgb(142,109,70)'}>Apply</Button>
               </HStack>
               <Stack>

                    <Flex justify={'space-between'} fontSize={'sm'}>
                         <Text>Subtotal</Text>
                         <Text>IDR {formatFrice(price)}.00</Text>
                    </Flex>
                    <Flex justify={'space-between'} fontSize={'sm'}>
                         <Text>Shipping</Text>
                         <Text>IDR {formatFrice(shippingPrice)}.00</Text>
                    </Flex>
               </Stack>
               <Flex justify={'space-between'}>
                    <Text>Total</Text>
                    <Text>IDR {formatFrice(price + shippingPrice)}.00</Text>
               </Flex>
          </Stack>
     )
}

const OrderSummaryComponent = ({ shippingPrice }) => {
     let price = 0
     let totalOrder = 0

     const [total, setTotal] = useState(0)
     const [isDesktop] = useMediaQuery('(min-width: 1280px)')

     useEffect(() => {
          !isDesktop && setTotal(totalOrder)
     }, [])

     return (
          <Stack>
               <Accordion border={'transparent'} allowToggle w='100%' >
                    <AccordionItem>
                         <h2>
                              <AccordionButton justifyContent={'space-between'} paddingInlineStart={0} paddingInlineEnd={0} style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 'none' }}>
                                   <Heading fontSize={'2xl'}>Order summary ({total})</Heading>
                                   <Box>
                                        <Box color={themeColor} as="span" flex='1' textAlign='left'>
                                             Show
                                        </Box>
                                        <AccordionIcon />
                                   </Box>

                              </AccordionButton>
                         </h2>
                         <AccordionPanel pb={4} paddingInlineStart={0} paddingInlineEnd={0} style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 'none' }}>
                              <Stack>
                                   {dataCheckout.map((x, i) => {
                                        price += x.price
                                        totalOrder += x.quantity

                                        return (
                                             <Flex gap={5} key={i}>
                                                  <Box pos={'relative'}>
                                                       <Circle pos={'absolute'} right={'-2'} top={'-2'} bgColor={'rgba(0,0,0,0.56)'} w={5} color={'white'} fontSize={'sm'}><Text>{x.quantity}</Text></Circle>
                                                       <Image src={x.thumbnail} aspectRatio={43 / 64} w={'86px'} />
                                                  </Box>
                                                  <Stack w='full'>
                                                       <Text>{x.name}</Text>
                                                       <Flex justify={'space-between'} align={'center'}>
                                                            <Text textTransform={'uppercase'}>{x.size ? x.size : 'All Size'}</Text>
                                                            <Text>IDR {formatFrice(x.quantity * x.price)}.00</Text>
                                                       </Flex>
                                                       {x.quantity > 1 &&
                                                            <Text>IDR {formatFrice(x.price)}.00/ea</Text>
                                                       }
                                                  </Stack>
                                             </Flex>
                                        )
                                   })}

                              </Stack>
                         </AccordionPanel>
                    </AccordionItem>
               </Accordion>
               <HStack>
                    <Input placeholder='Discount code or gift card' bgColor={'white'} />
                    <Button>Apply</Button>
               </HStack>
               <Stack>

                    <Flex justify={'space-between'} fontSize={'sm'}>
                         <Text>Subtotal</Text>
                         <Text>IDR {formatFrice(price)}.00</Text>
                    </Flex>
                    <Flex justify={'space-between'} fontSize={'sm'}>
                         <Text>Shipping</Text>
                         <Text>IDR {formatFrice(shippingPrice)}.00</Text>
                    </Flex>
               </Stack>
               <Flex justify={'space-between'}>
                    <Text>Total</Text>
                    <Text>IDR {formatFrice(price + shippingPrice)}.00</Text>
               </Flex>
          </Stack>
     )
}

const CheckoutPage = () => {
     const navigate = useNavigate()
     const [isDesktop] = useMediaQuery('(min-width: 1280px)')
     const [isChecked, setIsChecked] = useState({ notify: true, saveInfo: false })
     const [userData, setUserData] = useState({ country: 'Indonesia', province: 'aceh', shipping: 'SIUNT', billingAddress: 'same', shippingPrice: 995500 })
     const [newAddress, setNewAddress] = useState({})
     const [totalPrice, setTotalPrice] = useState(0)

     const fetchDataUser = async () => {
          const res = await setUserData({ ...dataUser, ...userData })
     }

     function convertToValidPhoneNumber(text) {
          var result = [];
          text = text?.replace(/[^\d]/g, "");
          while (text?.length >= 6) {
               result?.push(text?.substring(0, 4));
               text = text?.substring(4);
          }
          if (text?.length > 0) result.push(text);
          return result.join("-");
     }


     useEffect(() => {
          fetchDataUser()
          return () => {
               setUserData({ country: 'Indonesia', province: 'aceh', shipping: 'SIUNT', billingAddress: 'same' })
               setIsChecked({ notify: true, saveInfo: false })
               setNewAddress({})
          }
     }, [])

     useEffect(() => {
          return () => {

          }
     }, [userData])


     return (
          <Box>

               <HeaderCheckoutComponent />

               <SimpleGrid columns={isDesktop ? 2 : 1} templateColumns={isDesktop ? '60% 40%' : '100%'} minH={"100vh"} pos={'relative'}>
                    {!isDesktop &&
                         <Accordion border={'transparent'} allowToggle w='100%' pt={5}>
                              <AccordionItem>
                                   <h2>
                                        <AccordionButton justifyContent={'space-between'} px={10}>
                                             <Box>
                                                  <Box color={themeColor} as="span" flex='1' textAlign='left'>
                                                       Show order summary
                                                  </Box>
                                                  <AccordionIcon />
                                             </Box>
                                             <Text>IDR {formatFrice(totalPrice)}.00</Text>

                                        </AccordionButton>
                                   </h2>
                                   <AccordionPanel px={10} pt={0} pb={4} paddingInlineStart={0} paddingInlineEnd={0} style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 'none' }}>
                                        <ListOrderComponent shippingPrice={userData.shippingPrice} setTotalPrice={setTotalPrice} />
                                   </AccordionPanel>
                              </AccordionItem>
                         </Accordion>
                    }
                    <Stack my={5} gap={2} divider={<StackDivider borderColor={"1px solid rgb(233,233,233)"} />}>
                         <Stack px={55} gap={5}>
                              <Flex justify={"space-between"}>
                                   <Heading fontSize={"2xl"} fontWeight={"medium"}>Contact</Heading>
                                   <HStack align={"center"}>
                                        <Text color={"rgba(0,0,0,0.56)"}>Have an account?</Text>
                                        <Text cursor={'pointer'} color={"#0174BE"} textDecor={"underline"} onClick={() => navigate('/login')}>Login </Text>
                                   </HStack>
                              </Flex>
                              <Stack>
                                   <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input placeholder="Email" value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
                                   </FormControl>
                                   <Checkbox colorScheme='orange' defaultChecked={isChecked.notify} onChange={(e) => setIsChecked({ ...isChecked, notify: e.target.checked })}>
                                        Email me with news and offers
                                   </Checkbox>
                              </Stack>
                              <Heading fontSize={"2xl"} fontWeight={"medium"}>Delivery</Heading>
                              <FormControl>
                                   <FormLabel>Country</FormLabel>
                                   <Select defaultValue={userData?.country && userData.country} >
                                        {country.map((x, i) => (
                                             <option key={i} value={x.name}>{x.name}</option>
                                        ))}
                                   </Select>
                              </FormControl>
                              <HStack>
                                   <FormControl>
                                        <FormLabel>First name</FormLabel>
                                        <Input placeholder="First name" value={userData.firstName} onChange={(e) => setUserData({ ...userData, firstName: e.target.value })} />
                                   </FormControl>
                                   <FormControl>
                                        <FormLabel>Last name</FormLabel>
                                        <Input placeholder="Last name" value={userData.lastName} onChange={(e) => setUserData({ ...userData, lastName: e.target.value })} />
                                   </FormControl>
                              </HStack>
                              <FormControl>
                                   <FormLabel>District ( Kecamatan )</FormLabel>
                                   <Input placeholder="District" />
                              </FormControl>
                              <FormControl>
                                   <FormLabel>City</FormLabel>
                                   <Input placeholder="City" value={userData.city} onChange={(e) => setUserData({ ...userData, city: e.target.value })} />
                              </FormControl>
                              <HStack>
                                   <FormControl>
                                        <FormLabel>Province</FormLabel>
                                        <Select defaultValue={userData?.province && userData.province.toUpperCase()}>
                                             {province.map((x, i) => (
                                                  <option value={x.name} key={i}>{x.name}</option>
                                             ))}
                                        </Select>
                                   </FormControl>
                                   <FormControl>
                                        <FormLabel>Postal code</FormLabel>
                                        <Input placeholder="Postal code" value={userData.postal} onChange={(e) => setUserData({ ...userData, postal: e.target.value })} />
                                   </FormControl>
                              </HStack>
                              <FormControl>
                                   <FormLabel>Phone</FormLabel>
                                   <InputGroup>
                                        <Input placeholder="Phone number" value={convertToValidPhoneNumber(userData?.phone)} onChange={(e) => setUserData({ ...userData, phone: e.target.value })} />
                                        <Tooltip
                                             label="In case we need to contact you about your order"
                                             aria-label="More information"
                                             w={200}
                                             textAlign={"center"}
                                        >
                                             <InputRightElement>
                                                  <BsQuestionCircle />
                                             </InputRightElement>
                                        </Tooltip>
                                   </InputGroup>
                              </FormControl>
                              <Checkbox colorScheme='orange' isChecked={isChecked.saveInfo} onChange={(e) => setIsChecked({ ...isChecked, saveInfo: e.target.checked })}> Save this information for next time</Checkbox>
                              <Stack>
                                   <Heading fontSize={"xl"} fontWeight={"medium"}>Shipping method</Heading>
                                   <RadioGroup colorScheme='orange' value={userData?.shipping} onChange={(value) => setUserData({ ...userData, shipping: value })}>
                                        {shipping?.map((x, i) => (
                                             <Box onClick={() => setUserData({ ...userData, shipping: x.code, shippingPrice: x.price })} cursor={'pointer'} key={i} border={userData?.shipping === x.code ? `1px solid ${themeColor}` : '1px solid rgba(0,0,0,.045)'} p={5} roundedTop={i === 0 && 5} roundedBottom={shipping.length === i + 1 && 5} bgColor={userData?.shipping === x.code ? '#FCF5F0' : 'none'}>
                                                  <Flex justify={"space-between"} align={"center"}>
                                                       <Radio value={x.code} isChecked={userData?.shipping === x.code} >{x.name} - {x.code} {x.additional && "(Additional Zone)"}</Radio>
                                                       <Text>IDR{" "}{formatFrice(x.price)}.00</Text>
                                                  </Flex>
                                                  <Text color={textMuted} fontSize={'sm'} ms={5}>{x.estimated}</Text>
                                                  <HStack ms={5} w={'100%'} color={textMuted} fontSize={'sm'} >
                                                       <Text>Sending from</Text>
                                                       <Text textTransform={"uppercase"}>{x.location}</Text>
                                                       <Text>to</Text>
                                                       <Text textTransform={"uppercase"}>{userData.province}</Text>
                                                  </HStack>
                                             </Box>
                                        ))}
                                   </RadioGroup>
                              </Stack>
                              <Stack>
                                   <Heading fontSize={"2xl"} fontWeight={"medium"}> Payment </Heading>
                                   <Text color={textMuted}>All transactions are secure and encrypted.</Text>
                              </Stack>
                              <Box>
                                   <Box p={5} border={`1px solid ${themeColor}`} roundedTop={5} bgColor={'#FCF5F0'}>
                                        <Text>Payments via Midtrans</Text>
                                   </Box>
                                   <Box px={[5, 100, 100, 100]} py={5} border={'1px solid rgba(0,0,0,.045)'} textAlign={'center'} roundedBottom={5} bgColor={'rgba(0,0,0,.045)'}>
                                        <Icon as={TbDeviceDesktopShare} fontSize={'lg'} boxSize={'3.7142857142857144em'} />
                                        <Text>After clicking “Pay now”, you will be redirected to Payments via Midtrans to complete your purchase securely.</Text>
                                   </Box>
                              </Box>
                              <Heading fontSize={"xl"} fontWeight={"medium"}>Billing address</Heading>
                              <RadioGroup colorScheme='orange' value={userData.billingAddress} onChange={(value) => setUserData({ ...userData, billingAddress: value })}>
                                   <Box >
                                        <Box cursor={'pointer'} onClick={() => setUserData({ ...userData, billingAddress: 'same' })} p={5} bgColor={userData?.billingAddress === 'same' ? '#FCF5F0' : 'none'} border={userData.billingAddress === 'same' ? `1px solid ${themeColor}` : '1px solid rgba(0,0,0,.045)'} roundedTop={5}>

                                             <Radio isChecked={userData.billingAddress === 'same'} value={'same'}>Same as shipping address</Radio>
                                        </Box>
                                        <Box cursor={'pointer'} onClick={() => setUserData({ ...userData, billingAddress: 'different' })} p={5} bgColor={userData?.billingAddress === 'different' ? '#FCF5F0' : 'none'} border={userData.billingAddress === 'different' ? `1px solid ${themeColor}` : '1px solid rgba(0,0,0,.045)'} roundedBottom={userData.billingAddress === 'same' && 5}>

                                             <Radio isChecked={userData.billingAddress === 'different'} value='different'> Use a different billing address</Radio>
                                        </Box>
                                   </Box>
                                   {userData.billingAddress === 'different' &&
                                        <Stack bgColor={'rgba(0,0,0,.045)'} border={'1px solid rgba(0,0,0,.045)'} p={5} mt={0} roundedBottom={5}>
                                             <FormControl>
                                                  <FormLabel>Country</FormLabel>
                                                  <Select defaultValue={userData?.country && userData.country} >
                                                       {country.map((x, i) => (
                                                            <option key={i} value={x.name}>{x.name}</option>
                                                       ))}
                                                  </Select>
                                             </FormControl>
                                             <HStack>
                                                  <FormControl>
                                                       <FormLabel>First name</FormLabel>
                                                       <Input placeholder="First name" onChange={(e) => setNewAddress({ ...userData, firstName: e.target.value })} />
                                                  </FormControl>
                                                  <FormControl>
                                                       <FormLabel>Last name</FormLabel>
                                                       <Input placeholder="Last name" onChange={(e) => setNewAddress({ ...userData, lastName: e.target.value })} />
                                                  </FormControl>
                                             </HStack>
                                             <FormControl>
                                                  <FormLabel>District ( Kecamatan )</FormLabel>
                                                  <Input placeholder="District" />
                                             </FormControl>
                                             <FormControl>
                                                  <FormLabel>City</FormLabel>
                                                  <Input placeholder="City" onChange={(e) => setNewAddress({ ...userData, city: e.target.value })} />
                                             </FormControl>
                                             <HStack>
                                                  <FormControl>
                                                       <FormLabel>Province</FormLabel>
                                                       <Select>
                                                            {province.map((x, i) => (
                                                                 <option value={x.name} key={i}>{x.name}</option>
                                                            ))}
                                                       </Select>
                                                  </FormControl>
                                                  <FormControl>
                                                       <FormLabel>Postal code</FormLabel>
                                                       <Input placeholder="Postal code" onChange={(e) => setNewAddress({ ...userData, postal: e.target.value })} />
                                                  </FormControl>
                                             </HStack>
                                             <FormControl>
                                                  <FormLabel>Phone</FormLabel>
                                                  <InputGroup>
                                                       <Input placeholder="Phone number" onChange={(e) => setNewAddress({ ...userData, phone: e.target.value })} />
                                                       <Tooltip
                                                            label="In case we need to contact you about your order"
                                                            aria-label="More information"
                                                            w={200}
                                                            textAlign={"center"}
                                                       >
                                                            <InputRightElement>
                                                                 <BsQuestionCircle />
                                                            </InputRightElement>
                                                       </Tooltip>
                                                  </InputGroup>
                                             </FormControl>
                                        </Stack>
                                   }
                              </RadioGroup>
                              {!isDesktop && <OrderSummaryComponent shippingPrice={userData.shippingPrice} />}
                              <Button bgColor={themeColor} size={'lg'} color={'white'}>Pay Now</Button>
                         </Stack>

                         <FooterCheckoutComponent />

                    </Stack>

                    {isDesktop &&
                         <ListOrderComponent shippingPrice={userData?.shippingPrice} setTotalPrice={setTotalPrice} />
                    }

               </SimpleGrid>
          </Box>
     )
}

export default CheckoutPage