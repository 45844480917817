import { Box, Button, Container, Divider, Flex, FormControl, FormLabel, HStack, Heading, Image, Input, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Select, SimpleGrid, Stack, StackDivider, Text, Textarea, UnorderedList, useDisclosure, useNumberInput } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { dataCollections } from '../../Data/Collections'
import { useParams } from 'react-router-dom'
import { PriceTag } from '../../Components/PriceTag/PriceTag'
import { TbRulerMeasure } from "react-icons/tb";

// Styling
const ovo = {
     fontFamily: 'Ovo',
     fontWeight: 400,
}
const karla = {
     fontFamily: 'Karla',
     fontWeight: 400
}

const themeColor = '#0174BE'
const transparent = { bgColor: 'rgba(142,109,70,0.8)' }
const ProductPage = () => {
     const params = useParams()
     const { isOpen, onOpen, onClose } = useDisclosure()

     const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
          useNumberInput({
               step: 1,
               defaultValue: 1,
               min: 1,
               max: 6,
          })
     const inc = getIncrementButtonProps()
     const dec = getDecrementButtonProps()
     const input = getInputProps()

     const [product, setProduct] = useState({})
     const [review, setReview] = useState({})
     const [cart, setCart] = useState({ size: 'allSize', quantity: 1 })
     const [openReview, setOpenReview] = useState(false)

     const fetchDataProduct = async () => {
          const res = await dataCollections.find((x) => x.product_name === (params.product.replace(/-/g, ' ')))
          setProduct(res)
     }


     useEffect(() => {
          fetchDataProduct()
          return () => {
               setProduct({})
               setReview({})
               setOpenReview(false)
          }
     }, [])



     return (
          <Stack textAlign={'left'} gap={10} mb={10}>
               <SimpleGrid columns={[1, 2]} justifyContent={'left'} alignItems={'left'} textAlign={'left'} templateColumns={['100%', '60% 40%']}>
                    <SimpleGrid columns={2} gap={2} marginEnd={[0, 20]} mb={[10, 0]}>
                         <Image src={product?.product_thumbnail} objectFit={'cover'} w='full' />
                         <Image src={product?.product_thumbnail} objectFit={'cover'} w='full' />
                    </SimpleGrid>
                    <Stack align={'left'} justify={'left'} textAlign={'left'} gap={5}>
                         <Heading textAlign={'left'} textTransform={'capitalize'} style={ovo}>{product?.product_name}</Heading>
                         <PriceTag price={product?.product_price} currency={'IDR'} text={{ ...karla, fontSize: '20px' }} />
                         <Divider />
                         <Box>
                              <Flex justify={'space-between'}>
                                   <Text style={ovo} letterSpacing={'0.2em'} fontSize={'sm'}>SIZE</Text>
                                   {product?.size_guide &&
                                        <Button leftIcon={<TbRulerMeasure />} style={karla} variant={'link'} color='black' onClick={onOpen}>
                                             Size Guide
                                        </Button>
                                   }
                              </Flex>
                              {product?.product_size ? product.product_size.map((x, i) => (
                                   <Button key={i} variant={'outline'} rounded={0} colorScheme='none' style={karla} w={'fit-content'} textTransform={'uppercase'} mr={2} borderColor={cart.size === x.size ? 'black' : '#ededec'} onClick={() => setCart({ ...cart, size: x.size })} >{x.size}</Button>
                              )) : <Button variant={'outline'} rounded={0} colorScheme='none' style={karla} w={'fit-content'}>All Size</Button>}
                         </Box>
                         <Box>
                              <Text style={ovo} letterSpacing={'0.2em'} fontSize={'sm'}>QUANTITY</Text>
                              <HStack gap={0} m={0} p={0} border={'1px solid #ededec'} w={'fit-content'}>
                                   <Button rounded={0} {...dec} size={'sm'} variant={'ghost'}>-</Button>
                                   <Input variant={'unstyled'} textAlign={'center'} w={50} rounded={0} {...input} size={'sm'} />
                                   <Button rounded={0} {...inc} size={'sm'} variant={'ghost'}>+</Button>
                              </HStack>
                         </Box>
                         <Button rounded={0} color={'white'} colorScheme='none' style={ovo} bgColor={themeColor}>
                              Add to cart
                         </Button>
                         <UnorderedList style={karla} ps={5}>
                              <ListItem>Tersedia dalam 5 pilihan warna</ListItem>
                              <ListItem>
                                   <Flex gap={1} >
                                        Size:
                                        <Text textTransform={'uppercase'}>
                                             {product?.product_size?.map((element, index) => {
                                                  if (product.product_size.length > 0 && index + 1 < product.product_size.length)
                                                       return `${element.size.replace(/\//g, '-')}, `
                                                  else return element.size.replace(/\//g, '-')
                                             })}
                                        </Text>
                                   </Flex>
                              </ListItem>
                              <ListItem>Material: Soft Poly Sangat nyaman untuk dipakai beraktifitas.</ListItem>
                         </UnorderedList>
                         <Text style={{ ...karla, fontWeight: 700, fontStyle: 'italic' }}>*Terdapat sedikit perbedaan warna akibat cahaya/screen di masing-masing device yang digunakan*</Text>
                    </Stack>
               </SimpleGrid>

               <Stack h='500px' align={'left'} justify={'left'}>
                    <Heading style={ovo} fontSize={'2xl'} textAlign={'center'}>You may also like</Heading>
               </Stack>

               <Stack justify={'center'} align={'center'} gap={10}>
                    <Heading style={ovo} fontSize={'2xl'} textAlign={'center'}>Review Customer</Heading>
                    <HStack gap={5} justify={'center'} divider={<StackDivider />}>
                         <Box>
                              <Box></Box>
                              <Text style={karla}>Belum ada ulasan</Text>
                         </Box>

                         <Button rounded={0} color={'white'} _hover={transparent} colorScheme='none' style={{ ...karla, fontWeight: 'bold' }} bgColor={themeColor} onClick={() => setOpenReview(!openReview)} w={'3xs'}>
                              {openReview ? 'Cancel review' : 'Write review'}
                         </Button>
                    </HStack>
                    {openReview &&
                         <>
                              <Divider />
                              <Stack textAlign={'center'} gap={10} align={'center'} justify={'center'} >
                                   <Heading style={{ ...karla, fontWeight: 'bold' }} textAlign={'center'} fontSize={'2xl'} >Write review</Heading>
                                   <Text style={ovo} letterSpacing={'0.2em'} fontSize={'xs'}>RATING</Text>
                                   <FormControl>
                                        <FormLabel style={ovo} letterSpacing={'0.2em'} fontSize={'sm'} textAlign={'center'}>TITLE (100)</FormLabel>
                                        <Input style={karla} placeholder='Input your title of the review' rounded={0} />
                                   </FormControl>
                                   <FormControl>
                                        <FormLabel style={ovo} letterSpacing={'0.2em'} fontSize={'sm'} textAlign={'center'}>DESCRIPTION</FormLabel>
                                        <Textarea style={karla} placeholder='Write your review here' rounded={0} h={'150px'} />
                                   </FormControl>
                                   <FormControl>
                                        <FormLabel style={ovo} letterSpacing={'0.2em'} fontSize={'sm'} textAlign={'center'}>PICTURE / VIDEO (OPTIONAL)</FormLabel>
                                        <Input style={karla} placeholder='YouTube URL' rounded={0} />
                                   </FormControl>
                                   <FormControl>
                                        <FormLabel
                                             style={ovo} letterSpacing={'0.2em'} fontSize={'sm'} alignItems={'center'} justifyContent={'center'} textAlign={'center'} textTransform={'uppercase'} display={['block', 'block', 'block', 'flex']} >
                                             name (your name will be shown in public like
                                             <Flex align={'center'} justify={'center'}>

                                                  <Select style={karla} ms={1} color={themeColor} variant={'unstyled'} w={'fit-content'}>
                                                       <option>Jhon Smith</option>
                                                       <option>Jhon S.</option>
                                                       <option>J.S.</option>
                                                       <option>Anonim</option>
                                                  </Select>)
                                             </Flex>
                                        </FormLabel>
                                        <Input style={karla} placeholder='Input your name (public)' rounded={0} />
                                   </FormControl>
                                   <FormControl>
                                        <FormLabel style={ovo} letterSpacing={'0.2em'} fontSize={'sm'} textAlign={'center'}>E-MAIL</FormLabel>
                                        <Input style={karla} placeholder='Input your email (private)' rounded={0} />
                                   </FormControl>
                                   <HStack>
                                        <Button color={themeColor} style={{ ...karla, fontWeight: 'bold' }} size={'md'} variant={'unstyled'} border={`1px solid ${themeColor}`} _hover={{ color: 'rgba(142, 109, 70, 0.7)' }} rounded={0} px={2}>Cancel Review</Button>
                                        <Button bgColor={themeColor} style={{ ...karla, fontWeight: 'bold' }} color={'white'} rounded={0} size={'md'} colorScheme='none' _hover={transparent}>Submit Review</Button>
                                   </HStack>
                              </Stack>
                         </>
                    }
               </Stack>
               <Modal isOpen={isOpen} onClose={onClose} size={'lg'} isCentered>
                    <ModalOverlay bg={'#ffffff9c'} />
                    <ModalContent p={5} >
                         <ModalCloseButton />
                         <ModalBody>
                              <Image src={product?.size_guide} />
                         </ModalBody>
                    </ModalContent>
               </Modal>
          </Stack>
     )
}

export default ProductPage