export const store = [
  {
    name: "Tangerang",
    thumbnail:
      "https://www.dropbox.com/scl/fi/brjcbljjsek1xwliw1g5d/1700228880474-3.-Lokasi-Kami.jpeg?rlkey=9kzbu0wg4v7vwdavj6mbdcoxe&dl=0&raw=1",
    address: "PMB Ida soraya Amd.Keb",
    location:
      "ds jatimulya rt 01/07 no 8, Jatimulya, Kec. Kosambi, Kabupaten Tangerang, Banten 15211",
    day: "Setiap Hari",
    hour: "10.00 am - 21.00 pm",
    phone: " +62819-0844-1056",
    socials: [
      { type: "ig", link: "https://www.instagram.com/idasoraya88/" },
      {
        type: "wa",
        link: "https://api.whatsapp.com/send?phone=6281908441056&text=halo dok",
      },
      {
        type: "maps",
        link: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3967.3034426897457!2d106.67914830382533!3d-6.089761825604211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwMDUnMjMuMiJTIDEwNsKwNDAnNTQuMiJF!5e0!3m2!1sid!2sid!4v1699720642656!5m2!1sid!2sid",
      },
    ],
  },
];

export const metroStore = [
  {
    name: "Pemeriksaan ANC (kehamilan)",
    thumbnail:
      "https://ayosehat.kemkes.go.id/imagex/content/502d9dd334a3a4fc2f82c6f6556d4cb7.webp",
    location:
      "Periksakan kehamilan bunda secara rutin di Klinik kami: Pemeriksaan Kehamilan, Pemeriksaan Nifas, Pemeriksaan Bayi Baru Lahir, Keluarga Berencana",
    day: "Setiap Hari",
    hour: "08.00 - 21.00 ",
    socials: [
      {
        type: "wa",
        link: "https://www.google.com/maps/dir//Plaza+Indonesia,+Jl.+M.H.+Thamrin+No.Kav.+28-30,+Gondangdia,+Kec.+Menteng,+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta+10350/@-6.1938394,106.7871962,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69f42108e6d503:0x7d7fe17ad64a053f!2m2!1d106.8224233!2d-6.1939945!3e2",
      },
    ],
  },
  {
    name: "KB",
    thumbnail:
      "https://asset-a.grid.id/crop/0x0:0x0/700x465/photo/2022/06/29/keluarga-berencanajpg-20220629125756.jpg",
    location:
      "Pemeriksaan umum; Senam Hamil; Konseling Kesehatan Ibu dan Bayi; Laktasi Carea",
    day: "Setiap Hari",
    hour: "08.00 - 21.00",

    socials: [
      {
        type: "wa",
        link: "https://www.google.com/maps/dir//Plaza+Indonesia,+Jl.+M.H.+Thamrin+No.Kav.+28-30,+Gondangdia,+Kec.+Menteng,+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta+10350/@-6.1938394,106.7871962,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69f42108e6d503:0x7d7fe17ad64a053f!2m2!1d106.8224233!2d-6.1939945!3e2",
      },
    ],
  },
  {
    name: "Imunisasi",
    thumbnail:
      "https://res.cloudinary.com/dk0z4ums3/image/upload/v1650264833/attached_image/daftar-imunisasi-wajib-yang-harus-didapat-si-kecil.jpg",
    location: "Cara terbaik untuk melindungi buah hati anda dari penyakit",
    day: "Setiap Hari",
    hour: "08.00  - 21.00 ",

    socials: [
      {
        type: "wa",
        link: "https://www.google.com/maps/dir//Plaza+Indonesia,+Jl.+M.H.+Thamrin+No.Kav.+28-30,+Gondangdia,+Kec.+Menteng,+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta+10350/@-6.1938394,106.7871962,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69f42108e6d503:0x7d7fe17ad64a053f!2m2!1d106.8224233!2d-6.1939945!3e2",
      },
    ],
  },
  {
    name: "Persalinan 24 jam",
    thumbnail:
      "https://s2.bukalapak.com/uploads/content_attachment/2d182349454e581142937cb5/original/Main_Image_%286%29.jpg",
    location: "Persalinan normal di Klinik Bidan Ida Soraya dapat ditangani oleh dokter spesial kandungan dan Bidan. Percayakan persalinan normal bunda kepada klinik kami.",
    day: "Setiap Hari",
    hour: "24 Jam",

    socials: [
      {
        type: "wa",
        link: "https://www.google.com/maps/dir//Plaza+Indonesia,+Jl.+M.H.+Thamrin+No.Kav.+28-30,+Gondangdia,+Kec.+Menteng,+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta+10350/@-6.1938394,106.7871962,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69f42108e6d503:0x7d7fe17ad64a053f!2m2!1d106.8224233!2d-6.1939945!3e2",
      },
    ],
  },
  {
    name: "Cek lab dasar (ibu hamil)",
    thumbnail:
      "https://static.cdntap.com/tap-assets-prod/wp-content/uploads/sites/24/2021/07/tes-1-2.jpg?width=450&quality=90",
    location: "Bunda bisa cek perkembangan sikecil dengan USG 2D/4D dengan dokter SPOG.",
    day: "Setiap Hari",
    hour: "08.00 - 21.00",

    socials: [
      {
        type: "wa",
        link: "https://www.google.com/maps/dir//Plaza+Indonesia,+Jl.+M.H.+Thamrin+No.Kav.+28-30,+Gondangdia,+Kec.+Menteng,+Kota+Jakarta+Pusat,+Daerah+Khusus+Ibukota+Jakarta+10350/@-6.1938394,106.7871962,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x2e69f42108e6d503:0x7d7fe17ad64a053f!2m2!1d106.8224233!2d-6.1939945!3e2",
      },
    ],
  },
];
