export const shipping = [
	{
		id: 1,
		name: "SiCepat Ekspres",
		code: "SIUNT",
		additional: true,
		price: 995500,
		location: "tangerang",
		estimated: "1 to 3 business days",
	},
	{
		id: 2,
		name: "SiCepat Ekspres",
		code: "REG",
		additional: true,
		location: "tangerang",
		price: 1265000,
		estimated: "1 to 3 business days",
	},
];