import {
	Box,
	Container,
	Stack,
} from "@chakra-ui/react";
import { Fragment } from "react";
import AppFooter from "../Components/Footers/AppFooter";
import AppSideBar from "../Components/Sidebar/AppSideBar";
import themeConfig from "../Config/themeConfig";
import { useLocation } from "react-router-dom";
import CheckoutPage from "../Pages/Checkout/CheckoutPage";
import HomePage from "../Pages/Home/HomePage";
import PagesPage from "../Pages/Pages/PagesPage";
import FloatingContacts from "../Components/FloatingContacts/FloatingContacts";

function Layout({ children }) {
	let contentWidth =
		themeConfig.contentWidth === "full" ? "full" : "container.xl";
	const location = useLocation();

	return (
		<Stack pos={'relative'} gap={0}>
			{location?.pathname?.includes("checkout") ? (
				<CheckoutPage />
			) :
				location.pathname === '/' ?
					<Fragment>
						<AppSideBar />
						<Box minH={'100vh'} pt={0}>
							<HomePage />
						</Box>
						<Box>
							<AppFooter />
						</Box>
					</Fragment>
					:
					location.pathname.includes('pages') ?
						<Fragment>
							<AppSideBar />
							<Box minH={'100vh'} pt={0}>
								<PagesPage />
							</Box>
							<Box>
								<AppFooter />
							</Box>
						</Fragment>
						:
						(
							<Stack w={"full"}>
								<AppSideBar />
								<Container
									maxW={"full"}
									minH={"100vh"}
									alignItems="center"

								>
									<Box px={[5, 10, 50, 79]}>{children}</Box>
								</Container>
								<Stack>
									<AppFooter />
								</Stack>
							</Stack>
						)}
			<Stack   w='100%' >
				<FloatingContacts />
			</Stack>
		</Stack>
	);
}

export default Layout;
