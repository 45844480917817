export const dataUserAddress = {
	name: "John Doe",
	country: "Indonesia",
	addresses: [
		{
			firstName: "John",
			lastName: "Doe",
			company: "PT. Edrus",
			address1:
				" Metland Tangerang, Jl. Puri Utama No.7, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147",
			city: "Tangerang",
			country: "Indonesia",
			province: "Banten",
			postal: 15147,
			phone: "085775032912",
			default: true,
		},
		{
			firstName: "Jane",
			lastName: "Doe",
			company: "Importir.co",
			address1:
				" Jl. Puri Utama No.7, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147",
			city: "Tangerang",
			country: "Indonesia",
			province: "Banten",
			postal: 15147,
			phone: "08211198721",
			default: false,
		},
	],
};

export const dataUser = {
	firstName: "John",
	lastName: "Doe",
	company: "PT. Edrus",
	address1:
		" Metland Tangerang, Jl. Puri Utama No.7, RT.004/RW.008, Petir, Kec. Cipondoh, Kota Tangerang, Banten 15147",
	city: "Tangerang",
	country: "Indonesia",
	province: "Banten",
	postal: 15147,
	phone: "085775032912",
	default: true,
	email: 'johndoe@email.co'
};
