import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthenticationRouter from "./AuthenticationRouter";
import FrontRouter from "./FrontRouter";

function AuthRouter() {
    const authRouter = [...FrontRouter, ...AuthenticationRouter];
    return (
        <Routes>
            {authRouter.map((item, index) => {
                return <Route key={index} path={item.path} element={item.element} />;
            })}
        </Routes>
    );
}

export default AuthRouter;
