import { useEffect } from "react";
import { Box, Stack } from "@chakra-ui/react";
import Layout from "./Layouts";
import MainRouter from "./Router/MainRouter";
import AuthRouter from "./Router/AuthRouter";

import {
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from "./Apis/firebaseApi";
import { UseAuthStore } from "./Hooks/Zustand/store";
import Preloader from "./Components/Loading/Preloader";
import { useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, fetchToken } from "./Config/firebase";
import { increment } from "firebase/firestore";

function App() {
  const location = useLocation();

  const globalState = UseAuthStore();

  const handleRefresh = async () => {
    const company = "FRWsSi8wYKr0B3bdrCNr";
    const project = "2qBa6oTyVaa3ANcqamEA";

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const tokens = localStorage.getItem("tokens");
    const user = localStorage.getItem("user");

    const collectionName = "display";
    const docName = "track";
    const data = {
      seen_amount: increment(1),
      lastUpdatedSeen: new Date(),
    };

    try {
      const result = await updateDocumentFirebase(
        collectionName,
        docName,
        data
      );
      console.log(result); // Pesan toast yang berhasil
    } catch (error) {
      console.log("Terjadi kesalahan:", error);
    }

    const newToken = JSON.parse(tokens);
    const newUser = JSON.parse(user);
    const newIsLoggedIn = JSON.parse(isLoggedIn);

    globalState.setCurrentCompany(company);
    globalState.setCurrentProject(project);
    globalState.setTokens(newToken);
    globalState.setUser(newUser);
    globalState.setIsLoggedIn(newIsLoggedIn);
  };

  const uploadTokenToFirebase = async (token, user) => {
    if (token !== "") {
      const collectionName = "users";
      const docName = user.uid;
      const field = "tokenId";
      const values = [token];

      try {
        await arrayUnionFirebase(collectionName, docName, field, values);
      } catch (error) {
        console.log("Error occured:", error);
      }
    }
  };

  useEffect(() => {
    handleRefresh();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await fetchToken();
        if (token) {
          await uploadTokenToFirebase(token, user);
        }
        const tokenFirebase = await user.getIdToken(true);
        globalState.setAuthFirebaseToken(tokenFirebase);

        localStorage.setItem("tokenFirebase", JSON.stringify(tokenFirebase));

        globalState.setIsLoggedIn(true);
        globalState.setUser(user);
      } else {
        globalState.setIsLoggedIn(false);
      }
    });

    return () => {};
  }, []);

  return (
    <Stack>
      <Preloader />

      {location.pathname && (
        <Layout>
          <MainRouter />
          <AuthRouter />
        </Layout>
      )}
    </Stack>
  );
}

export default App;
