import { HStack, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BlogComponent = ({ data }) => {
     const karla = {
          fontFamily: 'Karla',
          fontWeight: 400
     }
     const navigate = useNavigate()
     return (
          <SimpleGrid columns={[1, 1, data.length % 2 === 0 ? 2 : 3, data.length % 2 === 0 ? 2 : 3, data.length % 2 === 0 ? 2 : 3]} gap={5}>
               {data.map((x, i) => (
                    <Stack key={i} cursor={'pointer'} onClick={() => navigate(`/blogs/journal/${encodeURIComponent(x?.title)?.replace(/%20/g, '-')}`)}>
                         <Image src={x.img} />
                         <HStack wrap={'wrap'}>
                              {x.tags.map((y, id) => (
                                   <HStack textAlign={'center'} justify={'center'} wrap={'wrap'} key={id}>
                                        <Text textTransform={'uppercase'} fontSize={'sm'} style={karla} key={id}>{y}</Text>
                                        <Text mt={'-2'}>.</Text>
                                   </HStack>
                              ))}
                         </HStack>
                         <Heading style={karla} fontSize={'xl'}>{x.title}</Heading>
                    </Stack>
               ))}
          </SimpleGrid>
     )
}

export default BlogComponent