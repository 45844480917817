import React from "react";
import HomePage from "../Pages/Home/HomePage";
import CollectionsPage from "../Pages/Collections/CollectionsPage";
import ProductPage from "../Pages/Product/ProductPage";
import SettingPage from "../Pages/Setting/SettingPage";
import AddressPage from "../Pages/Setting/AddressPage";
import CheckoutPage from "../Pages/Checkout/CheckoutPage";
import CartPage from "../Pages/Cart/CartPage";
import PagesPage from "../Pages/Pages/PagesPage";
import BlogsPage from "../Pages/Blogs/BlogsPage";
import SingleBlogPage from "../Pages/Blogs/SingleBlogPages/SingleBlogPage";
import AboutPage from "../Pages/About/AboutPage";
import ProfilePage from "../Pages/Profile/ProfilePage";
import LayananPage from "../Pages/Layanan/LayananPage";
import EdukasiPage from "../Pages/Edukasi/EdukasiPage";
import NamaKelompokPage from "../Pages/NamaKelompok/NamaKelompokPage";
import TestimoniPage from "../Pages/Testimoni/TestimoniPage";

const HomeRouter = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: '/about',
    element: <AboutPage />
  },
  {
    path: '/profile',
    element: <ProfilePage />
  },
  {
    path: "/account",
    element: <SettingPage />,
  },
  {
    path: "/layanan",
    element: <LayananPage />,
  },
  {
    path: "/edukasi",
    element: <EdukasiPage />,
  },
  {
    path: "/booking",
    element: <CartPage />,
  },
  {
    path: "/nama-kelompok",
    element: <NamaKelompokPage />,
  },
  {
    path: '/collections/:type',
    element: <CollectionsPage />
  },
  {
    path: '/collections/:type/product/:product',
    element: <ProductPage />
  },
  {
    path: "/account",
    element: <SettingPage />,
  },
  {
    path: "/pages/:type",
    element: <PagesPage />,
  },

  {
    path: "/blogs",
    element: <BlogsPage />,
  },
  {
    path: "/blogs/:title",
    element: <SingleBlogPage />,
  },

  {
    path: "/testimoni",
    element: <TestimoniPage />,
  },

];

export default HomeRouter;
