import { Avatar, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase } from '../../Apis/firebaseApi';

function NamaKelompokPage() {

  const [dataKelompok, setDataKelompok] = useState([])

  const getData = [
    "1. Aditia Putri Asriyani",
    "2. Anggini Sallykamaya",
    "3. Assyiffa Hernanda",
    "4. Carmelita Nur Fitriana",
    "5. Eka santi",
    "6. Ida Soraya",
    "7. Iin Indriyani",
    "8. Intan Dwi Lestari",
    "9. Islamiati Hardianti",
    "10. Mailinda",
    "11. Maryanah ",
    "12. Mina Elvira",
    "13. Miranda Putri K",
    "14. Nabila Siti Kholisoh",
    "15. Novia Rina",
    "16. Primera Ayunda",
    "17. Siti Hartinah",
    "18. Siska Widyawati",
    "19. Siti Anggi Damyati ",
    "20. Siti Masnu'ah",
    "21. Sumiyati",
    "22. Rukyani",
    "23. Umyati",
    "24. Yeni Wahyuni",
  ]

  const fetchData = async () => {
    const conditions = [
      { field: "type", operator: "==", value: "kelompok" },
    ];
    const sortBy = { field: "createdAt", direction: "desc" };
    const limitValue = 10;

    try {
      const res = await getCollectionFirebase(
        "users",
        conditions,
        sortBy,
        limitValue
      );
      setDataKelompok(res);
    } catch (error) {
      console.log(error, "ini error");
    }
  };

  useEffect(() => {
    fetchData()

    return () => {
      setDataKelompok([])
    }
  }, [])

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={5}>
        <Text fontWeight={"bold"} fontSize="xl">Nama Kelompok</Text>
        <Stack>
          {getData?.length > 0 && (
            <SimpleGrid columns={[1, 1, 3]} gap={5}>
              {getData.map((x, index) => {
                return (
                  <Flex key={index} align={'center'} bgColor="gray.100" p={3} shadow="md" direction={'column'}>
                    <Avatar src={"xxxx"} mb={2} />
                    <Stack spacing={-1} align={'center'}>
                      <Text fontWeight={600}>{x}</Text>
                      {/* <Text fontSize={'sm'} color={"gray.800"}>
                        {x}
                      </Text> */}
                    </Stack>
                  </Flex>
                )
              })}

            </SimpleGrid>
          )}

        </Stack>
      </Stack>
    </Stack>
  )
}

export default NamaKelompokPage