import { Box, Button, Container, Divider, Flex, FormControl, FormLabel, HStack, Heading, Image, Input, SimpleGrid, Stack, Text, Textarea, useMediaQuery } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { dataCheckout } from '../../Data/Collections'
import { PriceTag } from '../../Components/PriceTag/PriceTag'
// Styling
const ovo = {
     fontFamily: 'Ovo',
     fontWeight: 400,
}
const karla = {
     fontFamily: 'Karla',
     fontWeight: 400
}

const themeColor = '#0174BE'

const CartPage = () => {
     const navigate = useNavigate()
     let totalPrice = 0
     const [isDesktop] = useMediaQuery('(min-width: 1280px)')
     const [inputValues, setInputValues] = useState(dataCheckout.map(x => x.quantity || 1));

     const handleIncrement = (index, max) => {
          if (inputValues[index] < max) {
               setInputValues(prevValues => {
                    const newValues = [...prevValues];
                    newValues[index] += 1;
                    return newValues;
               });
          }
     };

     const handleDecrement = (index) => {
          if (inputValues[index] > 1) {
               setInputValues(prevValues => {
                    const newValues = [...prevValues];
                    newValues[index] -= 1;
                    return newValues;
               });
          }
     };

     const InputQuantityComponent = ({ i }) => {
          return (
               <HStack gap={0} >
                    <Button
                         rounded={0}
                         size="sm"
                         variant="ghost"
                         onClick={() => handleDecrement(i)}
                    >
                         -
                    </Button>
                    <Input
                         variant="unstyled"
                         textAlign="center"
                         w={[10, 50, 50, 50]}
                         rounded={0}
                         value={inputValues[i]}
                         onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (!isNaN(value) && value >= 1 && value <= 6) {
                                   setInputValues(prevValues => {
                                        const newValues = [...prevValues];
                                        newValues[i] = value;
                                        return newValues;
                                   });
                              }
                         }}
                         size="sm"
                    />
                    <Button
                         rounded={0}
                         size="sm"
                         variant="ghost"
                         onClick={() => handleIncrement(i, 6)}
                    >
                         +
                    </Button>
               </HStack>
          )
     }
     return (
          <Flex justify={'center'} align={'center'} mb={10}>
               <Stack gap={isDesktop ? 10 : 5} mt={isDesktop ? 5 : 0} w={isDesktop ? '70%' : '100%'} >
                    <Box textAlign={'center'}>
                         <Heading textTransform={'capitalize'} size="lg" style={ovo}>Cart</Heading>
                         <Text style={karla} textDecor={'underline'} cursor={'pointer'} onClick={() => navigate('/collections/all')}>Continue shopping</Text>
                    </Box>
                    <HStack textTransform={'uppercase'} w='100%' gap={'8%'} justify={'end'} display={isDesktop ? 'flex' : 'none'}>
                         <Text style={{ ...ovo, fontWeight: 500 }} fontSize={'sm'} letterSpacing={'0.2em'} textTransform={'uppercase'}>quantity</Text>
                         <Text style={{ ...ovo, fontWeight: 500 }} fontSize={'sm'} letterSpacing={'0.2em'} textTransform={'uppercase'}>total</Text>
                    </HStack>
                    <Divider />
                    {dataCheckout.map((x, i) => {
                         totalPrice += x.price
                         return (
                              <HStack w={'100%'} gap={isDesktop ? 10 : 5} key={i} >
                                   <Image aspectRatio={2 / 3} w={[90, 150, 150, 150]} src={x.thumbnail} onClick={() => navigate(`/collections/all/product/${encodeURIComponent(x.name).replace(/%20/g, '-')}`)} />

                                   <Stack w={isDesktop ? '60%' : '100%'} wrap={'wrap'}>
                                        <Box onClick={() => navigate(`/collections/all/product/${encodeURIComponent(x.name).replace(/%20/g, '-')}`)}>
                                             <Text style={karla} flexWrap={'wrap'}>{x.name}</Text>
                                             <Text textTransform={'uppercase'} style={karla}>{x?.size ? x.size : 'All Size'}</Text>
                                        </Box>
                                        {!isDesktop &&
                                             <HStack justify={'space-between'} w='100%'>
                                                  <InputQuantityComponent i={i} />
                                                  <PriceTag price={x.price} text={karla} currency={'IDR'} />
                                             </HStack>
                                        }
                                   </Stack>
                                   {isDesktop &&
                                        <>
                                             <InputQuantityComponent i={i} />
                                             <PriceTag price={x.price} text={karla} currency={'IDR'} />
                                        </>
                                   }
                              </HStack>
                         )
                    })}
                    <Divider />
                    <SimpleGrid columns={isDesktop ? 2 : 1} gap={isDesktop ? 10 : 5}>
                         <FormControl>
                              <FormLabel fontSize={'sm'} style={ovo} textTransform={'uppercase'} letterSpacing={'0.2em'}>order note</FormLabel>
                              <Textarea style={karla} />
                         </FormControl>
                         <Stack gap={5}>
                              <HStack justify={'space-between'}>
                                   <Text style={ovo} letterSpacing={'0.2em'} textTransform={'uppercase'}>subtotal</Text>
                                   <PriceTag price={totalPrice} currency={'IDR'} text={karla} />
                              </HStack>
                              <Text align={'center'} style={karla} fontSize={'sm'}>Shipping, taxes, and discount codes calculated at checkout.</Text>
                              <Button rounded={0} bgColor={themeColor} color='white' size={'lg'} style={ovo} colorScheme='none' _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }} onClick={() => navigate('/checkout')}>Checkout</Button>
                         </Stack>
                    </SimpleGrid>
               </Stack>
          </Flex>
     )
}

export default CartPage