'use client'

import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
    Button,
    Link,
    Badge,
    useColorModeValue,
} from '@chakra-ui/react'

export default function SocialProfileCard() {

    const handleWhatsapp = (data) => {
        const message = encodeURIComponent(`Halo ibu bidan,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
        const source = encodeURIComponent(window.location.href);
        const url = `https://api.whatsapp.com/send?phone=6281908441056&text=${message}%0A%0ASource:%20${source}`;
        window.open(url, '_blank');
    }
    
    return (
        <Center py={6}>

        <Box
            maxW={'500px'}
            w={'full'}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}>
            <Avatar
                size={'xl'}
                src={
                    'https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/WhatsApp%20Image%202023-12-04%20at%2017.53.07.jpeg?alt=media&token=143cb193-24e0-437f-9866-14dcd12313a3'
                }
                mb={4}
                pos={'relative'}
                _after={{
                    content: '""',
                    w: 4,
                    h: 4,
                    bg: 'green.300',
                    border: '2px solid white',
                    rounded: 'full',
                    pos: 'absolute',
                    bottom: 0,
                    right: 3,
                }}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'}>
                Ida soraya Amd.Keb
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
                @idasoraya88
            </Text>
            <Text
                textAlign={'center'}
                color={useColorModeValue('gray.700', 'gray.400')}
                px={3}>
                Praktik bidan mandiri ida soraya{' '}
            </Text>

            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
                <Badge
                    px={2}
                    py={1}
                    bg={useColorModeValue('gray.50', 'gray.800')}
                    fontWeight={'400'}>
                    #bidan
                </Badge>
            </Stack>

            <Stack mt={8} direction={'row'} spacing={4}>
                <Button
                    flex={1}
                    fontSize={'sm'}
                    rounded={'full'}
                    onClick={() => handleWhatsapp()}
                    _focus={{
                        bg: 'gray.200',
                    }}>
                    Message
                </Button>
                <Button
                    flex={1}
                    onClick={() => window.open("https://www.instagram.com/idasoraya88/", "_blank")}
                    fontSize={'sm'}
                    rounded={'full'}
                    bg={'blue.400'}
                    color={'white'}
                    boxShadow={
                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                    }
                    _hover={{
                        bg: 'blue.500',
                    }}
                    _focus={{
                        bg: 'blue.500',
                    }}>
                    Follow
                </Button>
            </Stack>
        </Box>
        </Center>
    )
}