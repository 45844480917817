import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
    SimpleGrid,
    Divider,
    HStack,
    FormControl,
    FormLabel,
    Input,
    Button,
    Textarea,
    Spinner,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { addDocumentFirebase, getCollectionFirebase } from '../../Apis/firebaseApi';

interface Props {
    children: React.ReactNode;
}

const Testimonial = (props: Props) => {
    const { children } = props;
    return <Box>{children}</Box>;
};

const TestimonialContent = (props: Props) => {
    const { children } = props;
    const boxShadowColor = useColorModeValue('white', 'gray.800');

    const contentStyle = {
        bg: boxShadowColor,
        boxShadow: 'lg',
        p: 8,
        rounded: 'xl',
        align: 'center',
        pos: 'relative',
        _after: {
            content: `""`,
            w: 0,
            h: 0,
            borderLeft: 'solid transparent',
            borderLeftWidth: 16,
            borderRight: 'solid transparent',
            borderRightWidth: 16,
            borderTop: 'solid',
            borderTopWidth: 16,
            borderTopColor: boxShadowColor,
            pos: 'absolute',
            bottom: '-16px',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    };

    return <Stack {...contentStyle}>{children}</Stack>;
};

const TestimonialHeading = (props: Props) => {
    const { children } = props;
    return (
        <Heading as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    );
};

const TestimonialText = (props: Props) => {
    const { children } = props;
    const textColor = useColorModeValue('gray.600', 'gray.400');

    return (
        <Text textAlign={'center'} color={textColor} fontSize={'sm'}>
            {children}
        </Text>
    );
};

interface TestimonialAvatarProps {
    src: string;
    name: string;
    title: string;
}

const TestimonialAvatar = (props: TestimonialAvatarProps) => {
    const { src, name, title } = props;

    const textColor = useColorModeValue('gray.600', 'gray.400');

    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} mb={2} />
            <Stack spacing={-1} align={'center'}>
                <Text fontWeight={600}>{name}</Text>
                <Text fontSize={'sm'} color={textColor}>
                    {title}
                </Text>
            </Stack>
        </Flex>
    );
};

const TestimonialItem = (props: Props & TestimonialAvatarProps) => {
    const { children, ...avatarProps } = props;
    return (
        <Testimonial>
            <TestimonialContent>
                {children}
            </TestimonialContent>
            <TestimonialAvatar {...avatarProps} />
        </Testimonial>
    );
};

const TestimoniPage = () => {
    const [testimoniData, setTestimoniData] = useState([]);
    const [loading, setLoading] = useState(false)
    const themeColor = '#0174BE';



    const karla = {
        fontFamily: 'Karla',
        fontWeight: 400,
    };
    const ovo = {
        fontFamily: 'Ovo',
        fontWeight: 400,
    };

    const [commentData, setCommentData] = useState({
        name: '',
        layanan: '',
        message: '',
    });

    const { name, layanan, message } = commentData;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCommentData({
            ...commentData,
            [name]: value,
        });
    };

    const fetchData = async () => {
        const conditions = [
            { field: 'active', operator: '==', value: true },
        ];
        const sortBy = { field: 'createdAt', direction: 'desc' };
        const limitValue = 9;

        try {
            const res = await getCollectionFirebase(
                'testimoni',
                conditions,
                sortBy,
                limitValue
            );
            setTestimoniData(res);
        } catch (error) {
            console.log(error, 'ini error');
        }
    };

    const handlePostComment = async () => {
        if (!name || !layanan || !message) {
            alert('Please fill all fields');
            return;
        }

        setLoading(true)

        const newTestimoni = {
            name: name,
            heading: layanan,
            text: message,
            active: true,
            createdAt: new Date(),
        };

        try {
            await addDocumentFirebase('testimoni', newTestimoni, "globalState.currentCompany");
            setCommentData({ name: '', layanan: '', message: '' }); // Bersihkan form setelah pengiriman komentar
            fetchData();
        } catch (error) {
            console.error(error);
            // Handle error jika terjadi kesalahan saat menyimpan testimoni
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();

        return () => {
            setTestimoniData([]);
        };
    }, []);

    return (
        <Stack>
            <Box bg={useColorModeValue('gray.100', 'gray.700')}>
                <Container maxW={'7xl'} p={[1, 1, 5]} as={Stack} spacing={12}>
                    <Stack spacing={0} align={'center'}>
                        <Heading>Testimoni Klink Mandiri</Heading>
                        <Text>We have been working with clients around the world</Text>
                    </Stack>
                    {testimoniData?.length > 0 && (
                        <SimpleGrid
                            columns={[1, 3, 3]}
                            spacing={{ base: 10, md: 4, lg: 10 }}
                        >
                            {testimoniData.map((x, index) => {
                                return (
                                    <TestimonialItem
                                        key={index}
                                        src={x?.image}
                                        name={x?.name}
                                        title={x.title}
                                    >
                                        <TestimonialHeading>{x?.heading}</TestimonialHeading>
                                        <TestimonialText>
                                            {x?.text}
                                        </TestimonialText>
                                    </TestimonialItem>
                                );
                            })}
                        </SimpleGrid>
                    )}
                </Container>
            </Box>
            <Divider />
            <Stack gap={5}>
                <Heading fontSize={'3xl'} style={ovo}>
                    Leave a comment
                </Heading>
                <HStack style={karla}>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                            name="name"
                            rounded={0}
                            value={name}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Layanan</FormLabel>
                        <Input
                            name="layanan"
                            rounded={0}
                            value={layanan}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </HStack>
                <FormControl>
                    <FormLabel style={karla}>Message</FormLabel>
                    <Textarea
                        name="message"
                        rounded={0}
                        value={message}
                        onChange={handleInputChange}
                    />
                </FormControl>
                <Text style={karla}>
                    Please note, comments must be approved before they are published
                </Text>
                {loading ? (
                    <Stack>
                        <Spinner size={"md"} />
                    </Stack>
                ) : (
                    <Button
                        rounded={0}
                        bgColor={themeColor}
                        color="white"
                        w={'fit-content'}
                        style={ovo}
                        colorScheme="none"
                        _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }}
                        onClick={handlePostComment}
                    >
                        Post a comment
                    </Button>
                )}

                <HStack wrap={'wrap'} style={karla}>
                    <a>
                        This site is protected by reCAPTCHA and the Google{' '}
                        <a>Privacy Policy </a>
                        <a>and </a>
                        <a>Terms of Service </a>
                        <a>apply</a>
                    </a>
                </HStack>
            </Stack>
            <Divider />
        </Stack>
    );
};

export default TestimoniPage;