import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Accordion,
    Image,
    Spacer,
    Button,
    Badge,
    Collapse,
    Tooltip,
    AccordionItem,
    AccordionButton,
    useBreakpointValue,
    AccordionIcon,
    AccordionPanel,
    useToast,
    DrawerOverlay,
    DrawerHeader,
    DrawerBody,
    Divider,
    DrawerFooter,
    Textarea,
    Input,
    useNumberInput,
    Circle,
    SimpleGrid,
    VisuallyHidden,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiMenu,
    FiChevronDown,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import logoSide from '../../Assets/Image/1.png'
import { BsBag, BsCart3, BsJustifyLeft, BsPerson, BsPhone, BsSearch } from 'react-icons/bs';
import { HiAcademicCap, HiOutlineHeart, HiOutlineShoppingBag, HiTag, HiViewGrid } from 'react-icons/hi';
import { BiCategory } from 'react-icons/bi'

import { IoPeopleSharp, IoPersonOutline } from 'react-icons/io5';
import { auth } from '../../Config/firebase';
import { data } from './DataMenu';
import { UseAuthStore } from '../../Hooks/Zustand/store';

import { ImDropbox } from 'react-icons/im';
import AppSearchInput from '../Search/AppSearchInput';
import { AiOutlineClose } from 'react-icons/ai';

import { formatFrice } from "../../Utils/numberUtil";
import { dataCheckout } from '../../Data/Collections';
import { PriceTag } from '../PriceTag/PriceTag';
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import logoimage from "../../Assets/Image/logoklinik.jpg"


// Styling
const ovo = {
    fontFamily: 'Ovo',
    fontWeight: 400,
}
const karla = {
    fontFamily: 'Karla',
    fontWeight: 400
}



interface LinkItemProps {
    name: string;
    icon: IconType;
    linklabel: string;


}
const LinkItems: Array<LinkItemProps> = [

    {
        name: 'Home',
        icon: FiHome,
        linklabel: '/',
    },

    {
        name: 'Category',
        icon: HiViewGrid,
        linklabel: '/category',
    },

    {
        name: 'Academy',
        icon: HiAcademicCap,
        linklabel: '/academy',
    },

    {
        name: 'Consultation',
        icon: IoPeopleSharp,
        linklabel: '/consultation',
    },

    {
        name: 'News',
        icon: FiTrendingUp,
        linklabel: '/news',
    },

    {
        name: 'Crowdfund',
        icon: IoPeopleSharp,
        linklabel: '/crowdfund',
    },
];


// const SocialButton = ({
//     children,
//     label,
//     href,
// }: {
//     children: ReactNode;
//     label: string;
//     href: string;
// }) => {
//     return (
//         <Button
//             bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
//             rounded={'full'}
//             w={8}
//             h={8}
//             cursor={'pointer'}
//             as={'a'}
//             href={href}
//             display={'inline-flex'}
//             alignItems={'center'}
//             justifyContent={'center'}
//             transition={'background 0.3s ease'}
//             _hover={{
//                 bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
//             }}>
//             <VisuallyHidden>{label}</VisuallyHidden>
//             {children}
//         </Button>
//     );
// };

export default function AppSideBar({
    children
}: {
    children: ReactNode;

}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const globalState = UseAuthStore();

    const toast = useToast()

    const location = useLocation()


    const [desktopShow, setDesktopShow] = useState(true);
    const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });

    const handleLogout = () => {
        localStorage.clear();
        globalState.setTokens({})
        globalState.setUser({})
        globalState.setIsLoggedIn(false)

        toast({
            title: 'Klinik Ida',
            description: 'Berhasil Logout',
            status: 'success',
            isClosable: true,
            duration: 9000
        });
    }



    return (
        <Box  >
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent isDesktop onClose={onClose} logoSide={'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget_140x@2x.png?v=1643345083'}
                    />
                </DrawerContent>
            </Drawer>
            <MobileNav isScrolled onOpen={onOpen} handleLogout={handleLogout} globalState={globalState} />
            <Stack pt={location.pathname === '/' || location.pathname.includes('pages') ? 108 : 150} w='full' position='relative'>
                {/* <Stack zIndex={100} pt={150} w='full' position='relative'> */}

                {children}
            </Stack>

        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, logoSide, isDesktop, ...rest }: SidebarProps) => {



    const navigate = useNavigate()





    return (
        <Box
            transition="2s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={'full'}
            pos="fixed"
            h="full"
            overflowY='scroll'
            {...rest}>
            <Flex h="20" alignItems="center" mx="2" justifyContent="end">
                {/* <Image onClick={() => navigate('/')} w='200px' cursor='pointer' src={'https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget_140x@2x.png?v=1643345083'} /> */}


                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>

            <Stack
                w='full'
                p={5}
            >

                <Accordion allowToggle w={'full'}>

                    {data.map((x, i) => (
                        <AccordionItem
                            key={i} isDisabled={x.name === "Social Media" ? true : false}

                        >
                            <h2>
                                <AccordionButton paddingInlineStart={0} paddingInline={0} style={{ WebkitPaddingStart: 0, WebkitPaddingEnd: 0 }}>

                                    <HStack spacing={5} w='full' my={3} onClick={(() => navigate(x.link))}>
                                        {/* <Icon as={x.icon} boxSize={isDesktop ? 5 : 7} /> */}
                                        {isDesktop && (
                                            <Text
                                                textTransform={'uppercase'}
                                                fontWeight={500}
                                                fontSize="lg"
                                                noOfLines={1}
                                                style={karla}

                                            >
                                                {x.name}
                                            </Text>
                                        )}
                                        <Spacer />
                                        {x.submenu && <AccordionIcon />}

                                    </HStack>

                                </AccordionButton>
                            </h2>
                            {x.submenu ? (
                                <>
                                    <AccordionPanel>
                                        <Stack>
                                            {x.submenu?.map((subitem, i) => (
                                                <Link to={subitem.link} key={i}>
                                                    <HStack spacing="3">
                                                        <Icon
                                                            as={subitem.icon}
                                                            boxSize={5}
                                                        />
                                                        {isDesktop && (
                                                            <>
                                                                <Text
                                                                    pl={3}
                                                                    fontWeight={500}
                                                                    fontSize="sm"
                                                                    noOfLines={1}
                                                                >
                                                                    {subitem.name}
                                                                </Text>
                                                            </>
                                                        )}

                                                    </HStack>
                                                </Link>
                                            ))}
                                        </Stack>
                                    </AccordionPanel>
                                </>
                            ) : (
                                <>{null}</>
                            )}
                        </AccordionItem>
                    ))}
                </Accordion>
                <HStack gap={2} align={'left'} justify={'left'}>
                    {/* <a ref={'https://www.facebook.com/importir.org/?locale=id_ID/'}> */}
                    <IconButton
                        size="lg"
                        variant="ghost"
                        // color='#0174BE'
                        aria-label="open menu"
                        icon={<FaFacebook size={25} />}

                    />
                    {/* </a> */}

                    {/* <a href={'https://www.youtube.com/channel/UCt7EqweONcwImFGCjq33fNg'}> */}
                    <IconButton
                        size="lg"
                        variant="ghost"
                        // color='#0174BE'
                        aria-label="open menu"
                        icon={<FaInstagram size={25} />}

                    />
                    {/* </a>
                    <a href={'https://www.instagram.com/importirorg/'} > */}

                    <IconButton
                        size="lg"
                        variant="ghost"
                        // color='#0174BE'
                        aria-label="open menu"
                        icon={<FaTiktok size={25} />}

                    />
                    <IconButton
                        size="lg"
                        variant="ghost"
                        // color='#0174BE'
                        aria-label="open menu"
                        icon={<FaYoutube size={25} />}

                    />
                    {/* </a> */}

                </HStack>
            </Stack>



        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
}
const NavItem = ({ icon, linklabel, children, navigate, ...rest }: NavItemProps) => {
    return (
        <Stack onClick={() => navigate(linklabel)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'transparent',
                    color: 'cyan.400',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'cyan.400',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Stack>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, isScrolled, handleLogout, globalState, ...rest }: MobileProps) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [placement, setPlacement] = React.useState('right')


    const [drawerCart, setDrawerCart] = useState(false)


    let total = 0

    const [isOpen, setIsOpen] = useState(false);

    const handleTextClick = () => {
        setIsOpen(true);
    };

    const handleContainerMouseLeave = () => {
        setIsOpen(false);
    };

    const handleOpenDrawer = () => {
        setDrawerCart(true)
    }

    const handleCloseDrawer = () => {
        setDrawerCart(false)
    }
    const handleWhatsapp = (data) => {
        const message = encodeURIComponent(`Halo ibu bidan,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
        const source = encodeURIComponent(window.location.href);
        const url = `https://api.whatsapp.com/send?phone=6281908441056&text=${message}%0A%0ASource:%20${source}`;
        window.open(url, '_blank');
    }


    return (
        <Stack>

            <Stack
                color="black"
                zIndex={100}
                transition={'all 0.3s ease-in-out'}
                position={'fixed'}
                w={'100%'}
                h='auto'
                spacing={0}
                bgColor={'white'}
                {...rest}
            >

                <Stack h={'35px'} px={2} bgColor='#0174BE' w='100%' alignItems={['center', 'center', 'center']} justifyContent='center'>
                    {/* <Button onClick={() => console.log(globalState)}>check</Button> */}
                    <HStack color='white' spacing={[5, 5, 10]} px={1} display={{ base: 'center', md: 'center' }}>
                        <Stack>
                            <Text style={{ ...karla, fontWeight: 500 }} cursor={'pointer'} fontSize={['xs', 'sm', 'sm']}>Selamat Datang di Pelayanan Praktik Mandiri Bidan Ida Soraya.</Text>
                        </Stack>
                    </HStack>

                </Stack>

                <Collapse
                    in={isOpen}
                    onMouseEnter={handleTextClick}
                    onMouseLeave={handleContainerMouseLeave}
                    transition="ease-in-out 0.3s" >
                    <HStack shadow={"lg"} zIndex={100} h={'80px'} spacing={5} w='full' bgColor={'white'} alignItems='center' justifyContent={'center'}>
                        <AppSearchInput />
                        <AiOutlineClose size={20} cursor={'pointer'} onClick={handleContainerMouseLeave} />
                    </HStack>
                    <Box position="absolute" bgColor={'blackAlpha.700'} w='full' h={'100vh'} />

                </Collapse>


                <HStack

                    bgColor={'white'}
                    justifyContent={'space-evenly'}
                    px={[0, 0, 20]}
                    w={'100%'}
                    h='80px'
                    display={isOpen ? "none" : "flex"}
                    boxShadow={isScrolled ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none'}
                >



                    <Flex flex={{ base: 1 }} justify={{ base: 'start', md: 'start' }} gap={5} >

                        <HStack alignItems={'center'} justifyContent='center' spacing={0} >
                            <Image onClick={() => navigate('/')} w={['60px', '70px', '90px']} cursor='pointer' overflow={"hidden"} src={logoimage} />
                            <Text style={{ ...karla, fontWeight: 1000 }} color="blue.300">Bidan Ida Soraya</Text>
                        </HStack>






                        <HStack justifyContent={'center'} alignItems='center' w={'100%'} spacing={{ base: 3, lg: 10 }} fontSize='xs' wrap={'wrap'} textTransform={'uppercase'} color={'#0174BE'} px={1} display={{ base: 'none', md: 'flex' }}>
                            {data.map((x, index) => {
                                return (
                                    <Stack key={index}>
                                        <Text onClick={() => navigate(x.link)} cursor={'pointer'}>{x.name}</Text>
                                    </Stack>
                                )
                            })}
                        </HStack>


                    </Flex>



                    <Stack p={2}>
                        <Stack
                            flex={{ base: 1, md: 0 }}
                            justify={'center'}
                            justifyContent='center'
                            direction={'row'}
                            spacing={5}
                        >

                            <HStack alignItems={'center'} spacing={0} >
                                <Box className='Account' display={{ base: 'none', md: 'block' }}>
                                    <Tooltip label='Account' fontSize='md'>

                                        <Stack>

                                            <IconButton
                                                size="lg"
                                                variant="ghost"
                                                color='#0174BE'
                                                aria-label="open menu"
                                                icon={<BsPerson size={25} />}
                                                onClick={() => navigate(globalState?.isLoggedIn ? "/account" : "/login")}
                                            />

                                        </Stack>
                                    </Tooltip>
                                </Box>

                                <div className="search">

                                    <Stack>
                                        <IconButton
                                            size="lg"
                                            onClick={handleTextClick}
                                            color='#0174BE'
                                            variant="ghost"
                                            aria-label="open menu"
                                            icon={<BsSearch size={20} />}
                                        />

                                    </Stack>
                                </div>



                                <Box display={{ base: 'block', md: 'none' }} className="menu">
                                    {/* <Tooltip label='Search' fontSize='md'> */}

                                    <Stack>
                                        <IconButton
                                            size="lg"
                                            onClick={onOpen}
                                            color='#0174BE'
                                            variant="ghost"
                                            aria-label="open menu"
                                            icon={<BsJustifyLeft size={20} />}
                                        />

                                    </Stack>
                                    {/* </Tooltip> */}
                                </Box>
                                <div className="cart">
                                    <Tooltip label='Booking now' fontSize='md'>
                                        <Stack pos={'relative'}>
                                            <IconButton
                                                size="lg"
                                                variant="ghost"
                                                color='#0174BE'
                                                aria-label="open menu"
                                                icon={<BsPhone size={20} />}
                                                onClick={() => handleOpenDrawer()}
                                            />
                                            <Circle bgColor='#0174BE' size={'2'} bottom={'3'} right={3} pos={'absolute'} />
                                        </Stack>
                                    </Tooltip>
                                </div>

                            </HStack>
                        </Stack>
                    </Stack>
                </HStack>

                <Drawer placement={placement} onClose={handleCloseDrawer} size="sm" isOpen={drawerCart}>
                    <DrawerOverlay />
                    <DrawerContent >
                        <DrawerHeader borderBottomWidth='1px'>
                            <HStack p={3}>
                                <Text style={ovo}>Booking Now</Text>
                                <Spacer />
                                <AiOutlineClose size={20} cursor={'pointer'} onClick={handleCloseDrawer} />

                            </HStack>
                        </DrawerHeader>
                        <DrawerBody>
                            <Stack gap={5} my={4}>
                                <Text textTransform={'uppercase'} style={ovo} fontSize={'sm'} letterSpacing={'0.2em'}>Name</Text>
                                <Input rounded={0} />
                                <Text textTransform={'uppercase'} style={ovo} fontSize={'sm'} letterSpacing={'0.2em'}>Email</Text>
                                <Input rounded={0} />
                                <Text textTransform={'uppercase'} style={ovo} fontSize={'sm'} letterSpacing={'0.2em'}>Pesan</Text>
                                <Textarea rounded={0} />
                            </Stack>
                        </DrawerBody>
                        <Divider />


                        <DrawerFooter outline={0.5}>
                            <Stack w={'full'} spacing={5}>
                                <HStack>
                                    <Text textTransform={'uppercase'} style={ovo} letterSpacing={'0.2em'}>Subtotal</Text>
                                    <Spacer />
                                    <Text fontWeight={500} style={karla}>FREE</Text>
                                </HStack>
                                <Text style={karla} textAlign='center' fontSize={'xs'} color='gray.500'>Silahkan isi formulir ,lalu anda akan langsung di arahkan ke WhatsApp Kami.
                                </Text>
                                <Button cursor={"pointer"} rounded={0} bgColor={'black'} color='white' size={'lg'} style={ovo} colorScheme='none' _hover={{ bgColor: 'rgba(0, 0, 0, 0.8)' }} onClick={() => handleWhatsapp()}>Booking now</Button>
                            </Stack>
                        </DrawerFooter>
                    </DrawerContent>

                </Drawer>









            </Stack >
        </Stack >
    );
};