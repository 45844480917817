import React from 'react'

function MapEmbedComponents() {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3967.3034426897457!2d106.67914830382533!3d-6.089761825604211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwMDUnMjMuMiJTIDEwNsKwNDAnNTQuMiJF!5e0!3m2!1sid!2sid!4v1699720642656!5m2!1sid!2sid"
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    )
}

export default MapEmbedComponents