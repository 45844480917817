import { Box, Flex, Heading, HStack, IconButton, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { metroStore, store } from '../../Data/Store'
import { blog } from '../../Data/Blog'
import { lookbook } from '../../Data/Collections'
import BlogComponent from '../Pages/Components/BlogComponent'
import LocationComponent from '../Pages/Components/LocationComponent'
import MapEmbedComponents from '../../Components/Map/MapEmbedComponents'
import { BsGeoAltFill, BsInstagram, BsWhatsapp } from 'react-icons/bs'

const imgaeStoreLocation = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKfiBfFKicXxKDHyk486fPbFApUTBxPyMlAA&usqp=CAU'


const ovo = {
    fontFamily: 'Ovo',
    fontWeight: 400,
}
const karla = {
    fontFamily: 'Karla',
    fontWeight: 400
}


const LayananPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const params = "store"

    return (
        <Stack mb={20}>
            <Stack gap={10}>
                <Stack>
                
                    <Stack align={'center'} justify={'center'} bgColor={'rgba(1,1,1,0.4)'} w={'100%'} py={3} h='100%'  >
                        <Heading textTransform='capitalize' style={ovo} color={'white'}  fontSize={['xl', '3xl', '5xl', '7xl']}>Service</Heading>
                        <Text textTransform={'uppercase'} style={karla} color={'white'} letterSpacing={'0.2em'}>get clinic near you</Text>
                    </Stack>
                </Stack>
                <Stack px={[5, 10, 50, 79]} gap={[10, 10, 20, 20]} >
                    <LocationComponent data={metroStore} />
                    
                </Stack>
            </Stack>


        </Stack>
    )
}

export default LayananPage