import { Circle, Flex, Heading, HStack, IconButton, Stack, Text } from '@chakra-ui/react'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { FaUsers, FaWhatsapp } from 'react-icons/fa'
import { IoLogoWhatsapp } from 'react-icons/io'
import { IoPeople } from 'react-icons/io5'
import { db } from '../../Config/firebase'

const FloatingContacts = () => {

     const [reachData, setReachData] = useState(0)

     const handleWhatsapp = (data) => {
          const message = encodeURIComponent(`Halo ibu bidan,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
          const source = encodeURIComponent(window.location.href);
          const url = `https://api.whatsapp.com/send?phone=6281908441056&text=${message}%0A%0ASource:%20${source}`;
          window.open(url, '_blank');
     }

     useEffect(() => {
          const unsubCategory = onSnapshot(doc(db, "display", "track"), (doc) => {
               setReachData(doc?.data()?.seen_amount);

          });


          return () => {
               unsubCategory()
          }
     }, [])

     return (
          <Stack position={"relative"}>

               <Flex justify={'space-between'} w='100%' position={"fixed"} bottom={5} px={5}>


                    <Stack pos={'relative'} align={'center'} justify={'center'} cursor="pointer" >
                         <HStack bgColor={'#0174BE '} px={5} py={1} borderRadius="full" onClick={handleWhatsapp}>
                              <IconButton
                                   size={'lg'}
                                   variant={'none'}
                                   color={'white'}
                                   icon={<IoPeople size={35} />}

                              />
                              <HStack>
                                   <Heading fontSize={"lg"} color="white">{reachData || 0}</Heading>
                                   <Text fontSize={"sm"} color="white"> Visitor</Text>
                              </HStack>
                         </HStack>
                    </Stack>

                    <Stack pos={'relative'} align={'center'} justify={'center'} cursor="pointer" >
                         <HStack bgColor={'#0174BE '} px={5} py={1} borderRadius="full" onClick={handleWhatsapp}>
                              <IconButton
                                   size={'lg'}
                                   variant={'none'}
                                   color={'white'}
                                   icon={<IoLogoWhatsapp size={35} />}

                              />
                              <Text fontSize={"sm"} color="white">Daftar Sekarang</Text>
                         </HStack>
                    </Stack>

               </Flex>
          </Stack>
     )
}

export default FloatingContacts