import { Flex, IconButton, Image, SimpleGrid } from "@chakra-ui/react";
import { BsBag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const HeaderCheckoutComponent = () => {
     const navigate = useNavigate();
     return (
          <SimpleGrid
               columns={3}
               py={5}
               templateColumns={"10% 80% 10%"}
               borderBottom={"1px solid rgb(233,233,233)"}
          >
               <IconButton
                    size="lg"
                    variant="ghost"
                    color="#0174BE"
                    aria-label="open menu"
                    icon={<BsBag size={20} />}
                    alignItems={"center"}
                    h={"full"}
                    onClick={() => navigate('/cart ')}
               />
               <Flex justifyContent={"center"} align={"center"}>
                    <Image
                         aspectRatio={197 / 64}
                         onClick={() => navigate("/")}
                         w={"180px"}
                         cursor="pointer"
                         src={
                              "https://klamby.id/cdn/shop/files/Logo_klamby_baru_banget_140x@2x.png?v=1643345083"
                         }
                    />
               </Flex>
          </SimpleGrid>
     )
}
export default HeaderCheckoutComponent