import { Box, Flex, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LocationComponent from './Components/LocationComponent'
import { metroStore, store } from '../../Data/Store'
import BlogComponent from './Components/BlogComponent'
import { blog } from '../../Data/Blog'
import { lookbook } from '../../Data/Collections'

const imgaeStoreLocation = 'https://klamby.id/cdn/shop/files/A7S01354_1944x.jpg?v=1649036641'
const videoKlambyBlog = 'https://cdn.shopify.com/videos/c/o/v/7a88827ef51643cc9a001db1ff8e141d.mp4'


const ovo = {
     fontFamily: 'Ovo',
     fontWeight: 400,
}
const karla = {
     fontFamily: 'Karla',
     fontWeight: 400
}


const PagesPage = () => {
     const location = useLocation()
     const navigate = useNavigate()
     const params = location.pathname.replace('/pages/', '').replace(/-/g, ' ')

     return (
          <Stack mb={20}>
               {params.includes('store') ?
                    <Stack gap={10}>
                         <Stack pos={'relative'}>
                              <Box>
                                   <Image w={'100vw'} h={1000} objectFit={'cover'} src={imgaeStoreLocation} />
                              </Box>
                              <Stack align={'center'} justify={'center'} bgColor={'rgba(1,1,1,0.4)'} w={'100%'} pos={'absolute'} h='100%'  >
                                   <Heading textTransform='capitalize' style={ovo} color={'white'} fontSize={['xl', '3xl', '5xl', '7xl']}>{params}</Heading>
                                   <Text textTransform={'uppercase'} style={karla} color={'white'} letterSpacing={'0.2em'}>get klamby near you</Text>
                              </Stack>
                         </Stack>
                         <Stack px={[5, 10, 50, 79]} gap={[10, 10, 20, 20]} mt={[5, 5, 10, 10]}>
                              <Heading style={ovo} fontSize={'3xl'} textAlign={'center'}>Offline Store</Heading>
                              <LocationComponent data={store} />
                              <Heading style={ovo} fontSize={'3xl'} textAlign={'center'}>Metro</Heading>
                              <LocationComponent data={metroStore} />
                         </Stack>
                    </Stack>
                    :
                    <Stack gap={10}><Stack pos={'relative'}>
                         <video autoPlay muted loop >
                              <source src={videoKlambyBlog} type='video/mp4' />
                         </video>
                         <Stack bgColor={'rgba(1,1,1,0.4)'} w={'100%'} h='100%' mt={[5, 5, 5, 5, 10, 10]} >
                              <Stack bottom={0} align={'center'} pos={'absolute'} w={'100%'} h={[100, 100, 200, 200]} >
                                   <Heading textTransform='capitalize' style={ovo} color={'white'} fontSize={['4xl', '4xl', '7xl', '7xl']}>community gathering</Heading>
                                   <Text textTransform={'uppercase'} style={karla} color={'white'} letterSpacing={'0.2em'}>Bandung, 2023</Text>
                              </Stack>
                         </Stack>
                    </Stack>
                         <Stack px={[5, 10, 50, 79]} gap={[10, 10, 10, 10, 10, 20]} mt={[5, 5, 5, 5, 10, 10]}>
                              <Box position={'relative'}>
                                   <Heading style={ovo} fontSize={'3xl'} textAlign={'center'} mb={[5, 5, 5, 5, 10, 10]}>Blog posts</Heading>
                                   <Text style={karla} position={'absolute'} right={0} top={1} onClick={()=>navigate('/blogs/journal')} cursor={'pointer'}>View All</Text>
                              </Box>
                              <BlogComponent data={blog} />
                              <Heading style={ovo} fontSize={'3xl'} textAlign={'center'} mb={[5, 5, 5, 5, 10, 10]}>Lookbook</Heading>
                              <SimpleGrid columns={[1, 1, lookbook.length % 2 === 0 ? 2 : 3, lookbook.length % 2 === 0 ? 2 : 3, lookbook.length % 2 === 0 ? 2 : 3]} gap={[0, 0, 10, 10]}>
                                   {lookbook.map((x, i) => (
                                        <Image src={x.img} key={i} w={'full'} cursor={'pointer'} />
                                   ))}
                              </SimpleGrid>
                         </Stack>
                    </Stack>
               }

          </Stack>
     )
}

export default PagesPage