import { HStack, Text } from "@chakra-ui/react"

const FooterCheckoutComponent = () => {
     return (
          <HStack w='100%' justify={'center'} align={'center'} h='full' fontSize={'sm'} gap={3} wrap={'wrap'}>
               <Text color={"#0174BE"} textDecor={"underline"}>Refund policy </Text>
               <Text color={"#0174BE"} textDecor={"underline"}>Privacy policy</Text>
               <Text color={"#0174BE"} textDecor={"underline"}>Terms of service</Text>
               <Text color={"#0174BE"} textDecor={"underline"}>Contact information </Text>
          </HStack>
     )
}
export default FooterCheckoutComponent