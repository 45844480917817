export const imgCarouselHead = [
  {
    id: 1,
    image:
      "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/3.png?alt=media&token=34b5411b-fc6e-45ff-b70d-143fa2c160a3",
    alt: "monogram-header",
  },
  {
    id: 2,
    image:
      "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/4.png?alt=media&token=0cb8c8fc-e75f-41df-b658-8a8ab2b63db0",
    alt: "aerish-abaya-outer-header",
  },
  {
    id: 3,
    image:
      "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/5.png?alt=media&token=edb47ad8-31c8-48e2-8d4b-16c2752bc9ad",
    alt: "aerish-abaya-outer-header",
  },
];

export const imgCarouselMobile = [
  {
    id: 1,
    image:
      "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/3.png?alt=media&token=34b5411b-fc6e-45ff-b70d-143fa2c160a3",
    alt: "monogram-header",
  },
  {
    id: 2,
    image:
      "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/4.png?alt=media&token=0cb8c8fc-e75f-41df-b658-8a8ab2b63db0",
    alt: "aerish-abaya-outer-header",
  },
  {
    id: 3,
    image:
      "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/5.png?alt=media&token=edb47ad8-31c8-48e2-8d4b-16c2752bc9ad",
    alt: "aerish-abaya-outer-header",
  },
];
