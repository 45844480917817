import { Box, Button, Flex, Heading, Image, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LuSettings2 } from "react-icons/lu";
import { dataCollections } from '../../Data/Collections';
import { PriceTag } from '../../Components/PriceTag/PriceTag';

// Styling
const ovo = {
     fontFamily: 'Ovo',
     fontWeight: 400,
     textAlign: 'center',
}
const karla = {
     fontFamily: 'Karla',
     fontWeight: 400
}

//w='388.66px' h='581.98px'

//Data
const selectOption = [
     { value: 'sort', name: 'sort' },
     { value: 'featured', name: 'featured' },
     { value: 'best-selling', name: 'best selling' },
     { value: 'a-z', name: 'alphabetically, a-z' },
     { value: 'z-a', name: 'alphabetically, z-a' },
     { value: 'lowest', name: 'price, low to high' },
     { value: 'highest', name: 'price, high to low' },
     { value: 'newest', name: 'date, old to new' },
     { value: 'oldest', name: 'date, new to old ' }
]

const CollectionsPage = () => {
     const params = useParams()
     const navigate = useNavigate()
     
     return (
          <Stack gap={10} mb={10}>
               <Heading textAlign={'center'} textTransform={'capitalize'} style={ovo}>{params.type.replace(/\-/g, ' ')}</Heading>

               <Flex justify="space-between">
                    <Button leftIcon={<LuSettings2 />} variant={'outline'} rounded={0} style={karla} colorScheme='none' borderColor={'#ededec'}>
                         Filter
                    </Button>
                    <Select variant={'outline'} w='fit-content' rounded={0} textTransform='capitalize' borderColor={'#ededec'} style={karla} defaultValue={'featured'}>
                         {selectOption.map((item, index) => (
                              // console.log(item)
                              <option key={index} value={item.value}>{item.name}</option>
                         ))}
                    </Select>
               </Flex>

               <SimpleGrid columns={[2, 2, 3, 3, 3, 4]} gap={[2, 2, 2, 10]}>
                    {dataCollections.map((item, index) => {
                         return (
                              <Stack   key={index} pos={'relative'} minW={['100%', '100%', 'unset', 'unset', 'unset', 'unset']} onClick={() => navigate(`/collections/all/product/${encodeURIComponent(item.product_name).replace(/%20/g, '-')}`)} cursor={'pointer'}>
                                   {item.discount &&
                                        <Flex pos={'absolute'} right={0} bgColor={'#b73737'} top={0} >
                                             <Text px={2} py={1} fontSize={'sm'} style={karla} color={'white'} letterSpacing={'0.2em'}>SAVE {item.discount}%</Text>
                                        </Flex>
                                   }
                                   <Image objectFit={'cover'} minW={['70%', '70%', 'unset', 'unset', 'unset', 'unset']} aspectRatio={2 / 3} src={item.product_thumbnail} />
                                   <Box>
                                        <Text style={karla} fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}>
                                             {item.product_name}
                                        </Text>
                                        {item.discount ?
                                             <Flex gap={2}>
                                                  <Text as={'del'} style={karla} fontSize={['xs', 'xs', 'md', 'md']}>{<PriceTag currency={'IDR'} price={item.product_price} />}</Text>
                                                  <Text fontSize={['xs', 'xs', 'md', 'md']}>
                                                       <PriceTag currency={'IDR'} price={item.product_price - (item.product_price * item.discount / 100)} color='red' />
                                                  </Text>
                                             </Flex>
                                             :
                                             <Text style={{ karla }} fontSize={['xs', 'xs', 'md', 'md']}>{<PriceTag currency={'IDR'} price={item.product_price} />}</Text>
                                        }
                                   </Box>
                              </Stack>
                         )
                    })}
               </SimpleGrid>

          </Stack>
     )
}

export default CollectionsPage