import React, { useState, useEffect } from 'react';
import {
	AspectRatio,
	Box,
	Circle,
	HStack,
	IconButton,
	Image,
	Skeleton,
	useColorModeValue,
} from '@chakra-ui/react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { Carousel, CarouselSlide, useCarousel } from './Carousel';

export const Gallery = (props) => {
	const { images, aspectRatio } = props;
	const [currentSlide, setCurrentSlide] = useState(0);
	const [ref, slider] = useCarousel({
		loop: true,
		duration: 1000,
		slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
	});
	const hasPrevious = currentSlide !== 0;
	const hasNext = currentSlide < images.length - 1;

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (hasNext) {
				slider.current?.next();
			} else {
				setCurrentSlide(0); // Kembali ke gambar pertama jika sudah mencapai gambar terakhir
			}
		}, 3000);

		return () => {
			clearTimeout(timeout);
		};
	}, [currentSlide, hasNext, slider]);

	return (
		<Box
			bgColor="brand.1"
			w="full"
			position="relative"
			sx={{
				_hover: {
					'> button': {
						display: 'inline-flex',
					},
				},
			}}
		>
			<Carousel
				ref={ref}

			>
				{images.map((image, i) => (
					<Link to={image.link || ''} key={i}>
						<CarouselSlide
							transition="filter 1s ease-in-out"
							filter={`brightness(${currentSlide === i ? 1 : 0})`}
						>
							<AspectRatio
								ratio={aspectRatio}
								transition="all 200ms"
								opacity={currentSlide === i ? 1 : 0.4}
								_hover={{
									opacity: 1,
								}}
							>
								<Image
									src={image?.image ? image.image : image}
									objectFit="contain"
									alt="banner"
									
									fallback={<Skeleton />}
								/>
							</AspectRatio>
						</CarouselSlide>
					</Link>
				))}
			</Carousel>
			{hasPrevious && (
				<CarouselIconButton
					pos="absolute"
					left="3"
					top="50%"
					transform="translateY(-50%)"
					onClick={() => slider.current?.prev()}
					icon={<IoChevronBackOutline />}
					aria-label="Previous Slide"
				/>
			)}
			{hasNext && (
				<CarouselIconButton
					pos="absolute"
					right="3"
					top="50%"
					transform="translateY(-50%)"
					onClick={() => slider.current?.next()}
					icon={<IoChevronForwardOutline />}
					aria-label="Next Slide"
				/>
			)}
			<HStack position="absolute" width="full" justify="center" bottom="0" py="4">
				{images.map((_, index) => (
					<Circle
						key={index}
						size="2"
						bg={currentSlide === index ? 'white' : 'whiteAlpha.400'}
					/>
				))}
			</HStack>
		</Box>
	);
};

const CarouselIconButton = (props) => (
	<IconButton
		display="none"
		fontSize="lg"
		isRound
		boxShadow="base"
		bg={useColorModeValue('white', 'gray.800')}
		_hover={{
			bg: useColorModeValue('gray.100', 'gray.700'),
		}}
		_active={{
			bg: useColorModeValue('gray.200', 'gray.600'),
		}}
		_focus={{
			boxShadow: 'inherit',
		}}
		_focusVisible={{
			boxShadow: 'outline',
		}}
		{...props}
	/>
);
