import { Button, Container, Divider, FormControl, FormLabel, HStack, Heading, Image, Input, Stack, Text, Textarea } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from "moment";
import { FaFacebook, FaLongArrowAltLeft, FaPinterest, FaTwitter } from 'react-icons/fa';
import parse from 'html-react-parser';
import { blog } from '../../Data/Blog';
const themeColor = '#0174BE'

function AboutPage() {

  const params = useParams()
  const navigate = useNavigate()
  const [dataBlog, setDataBlog] = useState({})

  const fetchSingleDataBlog = async () => {
    const res = await blog.find((x) => x.title === "Tentang kami: Sejarah Singkat Klinik bidan Ida Soraya")
    setDataBlog(res)
  }
  const karla = {
    fontFamily: 'Karla',
    fontWeight: 400
  }
  const ovo = {
    fontFamily: 'Ovo',
    fontWeight: 400,
  }

  useEffect(() => {
    fetchSingleDataBlog()
  }, [])

  return (
    <Container maxW={'container.lg'} mb={10}>
      <Stack gap={10}>
        <Stack>
          <HStack textAlign={'center'} justify={'center'}>
            {dataBlog?.tags?.map((y, id) => (
              <HStack textAlign={'center'} justify={'center'} wrap={'wrap'} key={id}>
                <Text textTransform={'uppercase'} fontSize={'sm'} style={karla} key={id}>{y}</Text>
                <Text mt={'-2'}>.</Text>
              </HStack>
            ))}
            <Text textTransform={'uppercase'} fontSize={'sm'} style={karla} >{moment(dataBlog?.createdAt).format('ll')}</Text>
          </HStack>
          <Heading textAlign={'center'} style={ovo}>{dataBlog?.title}</Heading>
        </Stack>
        <Stack alignItems={"center"} justifyContent="center">
          <Image src={dataBlog?.img} w={"700px"} objectFit={'cover'} />
        </Stack>
        <Stack style={karla} textAlign={'justify'} >
          {dataBlog && dataBlog?.post &&
            parse(dataBlog?.post)
          }
        </Stack>
        <HStack gap={10}>
          <Button leftIcon={<FaFacebook />} style={karla} variant={'link'} color={'black'}>Share</Button>
          <Button leftIcon={<FaTwitter />} style={karla} variant={'link'} color={'black'}>Tweet</Button>
          <Button leftIcon={<FaPinterest />} style={karla} variant={'link'} color={'black'}>Pin it</Button>

        </HStack>
      
        <Button justifyContent={'center'} display={'flex'} alignItems={'center'} style={karla} leftIcon={<FaLongArrowAltLeft />} color={'black'} variant={'link'} onClick={() => navigate('/blogs/journal')}>Back to Journal</Button>
      </Stack>
    </Container>
  )
}

export default AboutPage