import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import logo from "../../Assets/Image/1.png";
import {
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
  MdVpnKey,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { checkLoginData } from "../../Hooks/Middleware/UserMiddleWare";

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";



function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataAccount, setDataAccount] = useState({
    email: "",
    password: "",
  })



  const toast = useToast();
  const navigate = useNavigate();

  const logout = async () => {

  };


  const handleAddData = (e) => {
    const { id, value } = e.target
    setDataAccount({ ...dataAccount, [id]: value })
  }



  const handleLogin = async () => {
    const validationResult = checkLoginData(dataAccount);
    if (!validationResult.success) {
      toast(validationResult.error);
      return;
    }

    setLoading(true)
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        dataAccount.email,
        dataAccount.password
      );
      const user = userCredential.user;


      if (user) {
        toast({
          title: "Login Successful",
          description: `You have successfully logged in as ${userCredential.user.email} `,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      navigate("/");
      // window.location.reload()
    } catch (error) {
      console.log(error, "ini error");
      toast({
        title: "Error",
        description:
          error.code === "auth/wrong-password"
            ? "Wrong email or password. Please try again."
            : "An error occurred. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const height = window.innerHeight;

  return (
    <>
      <Stack
        minH={height/1.2}
        bgColor="white"
        bgSize="cover"
        alignItems={"center"}
        justifyContent="center"
      >
        <Stack>
          <Stack
            justifyContent="center"
            shadow={'md'}
            alignItems="center"
            spacing={5}
            bgColor="white"
            p={10}
            borderRadius="xl"
          >
            <Box>
              <Text fontSize={'3xl'}>Login</Text>
            </Box>
            <Spacer />
            <Stack spacing={5} alignItems={'center'} justifyContent='center'>
              <Stack >
                <Text textTransform={'uppercase'} fontSize='sm'>Email</Text>
                <InputGroup
                  w={'350px'}
                >
                  <Input
                    placeholder="email"
                    fontSize={"md"}
                    shadow={3}
                    bgColor={"white"}
                    color={"black"}
                    id='email'
                    onChange={(e) => handleAddData(e)}
                  />
                </InputGroup>
              </Stack>

              <Stack >
                <HStack>
                  <Text textTransform={'uppercase'} textAlign='start' fontSize='sm'>Password</Text>
                  <Spacer />
                  <Text textTransform={'capitalize'} textAlign='start' fontSize='sm'>Forgot ?</Text>
                </HStack>

                <InputGroup
                  w={'350px'}
                >
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="password"
                    fontSize={"md"}
                    shadow={"md"}
                    type={showPassword ? "text" : "password"}
                    bgColor={"white"}
                    color={"black"}
                    id='password'
                    onChange={(e) => handleAddData(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Stack>

            <Stack pt={5} w='full'>
              <Button
                isLoading={loading}
                alignItems={"center"}
                justifyContent="center"
                bgColor={"#0174BE"}
                onClick={() => handleLogin()}
              >
                <Text color={"white"} >
                  Sign in
                </Text>
              </Button>
            </Stack>

            <Spacer />

            <HStack space={1} w='full' alignItems='flex-start' justifyContent={'flex-start'}>
              <Text color={"gray.600"} fontSize={["xs", null, "sm"]} cursor='pointer' onClick={() => navigate("/signup")}>
                Create Account
              </Text>
            </HStack>
          </Stack>
          <Spacer />

        </Stack>
      </Stack>
    </>
  );
}

export default LoginPage;
