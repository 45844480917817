import { Box, Button, Flex, Heading, HStack, IconButton, Image, SimpleGrid, Spacer, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BsBookmarkHeartFill, BsGeoAltFill, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { getCollectionFirebase } from '../../Apis/firebaseApi'
import SocialProfileCard from '../../Components/Cards/SocialProfileCard'
import MapEmbedComponents from '../../Components/Map/MapEmbedComponents'
import { lookbook } from '../../Data/Collections'
import { store } from '../../Data/Store'

function ProfilePage() {

    
    const ovo = {
        fontFamily: 'Ovo',
        fontWeight: 400,
    }
    const karla = {
        fontFamily: 'Karla',
        fontWeight: 400
    }

    const [isDesktop, isMobile] = useMediaQuery(['(min-width: 1280px)', '(max-width: 414px)'])


    const [dataCollection, setDataCollection] = useState([])
    const navigate = useNavigate()


    const getDataBaby = async () => {
      const conditions = [
        { field: "link", operator: "==", value: "xxx" },
      ];
      const sortBy = { field: "createdAt", direction: "desc" };
      const limitValue = 10;
  
      try {
        const res = await getCollectionFirebase(
          "customer_baby",
          conditions,
          sortBy,
          limitValue
        );
        setDataCollection(res, 'xxx')
  
      } catch (error) {
        console.log(error, "ini error");
      }
    }
  
    useEffect(() => {
      getDataBaby()
  
      return () => {
        setDataCollection([])
      }
    }, [])
  




    return (
        <Stack p={[1, 1, 5]}>


            <Stack>
                <SocialProfileCard />

            </Stack>
            <Stack p={[1, 1, 5]} gap={[10, 10, 10, 10]} >
                <Heading style={ovo} fontSize={'3xl'} textAlign={'center'}>Lokasi</Heading>
                <HStack spacing={5} flexDirection={["column", "row", "row"]} alignItems="flex-start">
                    <Stack spacing={10}>

                        {store?.map((x, i) => (
                            <Stack key={i} align={'center'} cursor={'pointer'} shadow="md" p={3}>
                                <Image src={x.thumbnail} h="250px" objectFit={"inherit"} />
                                <Heading textTransform={'capitalize'} fontSize={'2xl'} style={ovo}>{x.name}</Heading>
                                <Text style={{ ...karla, fontWeight: 'bold' }} >{x.address}</Text>
                                <Text style={karla}>{x.location}</Text>
                                <HStack >
                                    <Text style={karla}>{x.day}:</Text>
                                    <Text textTransform={'uppercase'} style={karla}>{x.hour}</Text>
                                </HStack>
                                {x.phone &&
                                    <Text style={karla}>{x?.phone}</Text>
                                }
                                <HStack>
                                    {x.socials.map((y, id) => (
                                        <a href={y.link} target='_blank' key={id} style={karla}>
                                            <IconButton
                                                size="lg"
                                                color='#0174BE'
                                                variant="ghost"
                                                aria-label="open menu"
                                                icon={y.type === 'ig' ? <BsInstagram size={20} /> : y.type === 'wa' ? <BsWhatsapp size={20} /> : y.type === 'maps' ? < BsGeoAltFill size={20} /> : <></>}

                                            />
                                            {x.socials.length === 1 && y.type === 'maps' && 'Get directions'}
                                        </a>

                                    ))

                                    }
                                </HStack>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack w={"100%"} spacing={3}>
                        <Stack >
                            <MapEmbedComponents />
                        </Stack>

                    </Stack>
                </HStack>
            </Stack>

            <Stack mt={10} pos={'relative'} px={[5, 10, 50, 79]} gap={10} mb={20}>
                <Box>
                    <Heading textAlign={'center'} style={ovo} fontSize={'3xl'}>Malaikat Kecil Yang Kami Bantu Hadir Ke Dunia</Heading>
                    <Text style={karla} pos={'absolute'} right={20} top={0} display={['none', 'none', 'block', 'block']}>View all</Text>
                </Box>
                <Flex gap={[5]} overflowX={'scroll'}>
                    {dataCollection?.map((item, index) => (
                        index < 6 &&
                        <HStack key={index} minH="500px" alignItems="flex-start" cursor={"pointer"}

                            _hover={{
                                transform: "scale(1.03)",
                                transition: "0.3s",
                            }}>
                            <Box w={["250px"]} _hover={{ cursor: 'pointer' }} >
                                <Stack key={index} pos={'relative'} onClick={() => navigate(`/collections/all/product/${encodeURIComponent(item.product_name).replace(/%20/g, '-')}`)}>

                                    <Image objectFit={'cover'} minW={['70%', '70%', 'unset', 'unset', 'unset', 'unset']} aspectRatio={2 / 3} src={item.image} />
                                    <Box>
                                        <HStack alignItems={"flex-start"}>
                                            <Stack>
                                                <Text style={karla} fontWeight={500} textTransform="capitalize" fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}>
                                                    {item.name}
                                                </Text>

                                                <Text style={karla} fontWeight={500} textTransform="capitalize" fontSize={['md', 'md', 'lg', 'lg', 'lg', 'xl']}>
                                                    20-10-2023
                                                </Text>
                                            </Stack>
                                            <Spacer />
                                            <Stack>
                                                <BsBookmarkHeartFill size={30} color="red" />
                                            </Stack>
                                        </HStack>


                                    </Box>
                                </Stack>
                            </Box>



                            <Stack alignItems={"center"} h="full" justifyContent="center">
                                {index + 1 === dataCollection.length && isMobile &&
                                    <Stack align={'center'} justify={'center'}>
                                        <Button rounded={0} variant={'outline'} style={karla} colorScheme='none' border={'1px solid #ededec'} p={5} w={'fit-content'} h='fit-content' onClick={() => navigate(`/collections/all/product/${encodeURIComponent(item.product_name).replace(/%20/g, '-')}`)}>View all <br /> {dataCollection.length} products</Button>
                                    </Stack>
                                }
                            </Stack>
                        </HStack>
                    ))}
                </Flex>
            </Stack>

        </Stack>
    )
}

export default ProfilePage