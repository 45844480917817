import { HStack, Heading, IconButton, Image, SimpleGrid, Stack, Text, Spacer } from '@chakra-ui/react'
import React from 'react'
import { BsGeoAltFill, BsInstagram, BsWhatsapp } from 'react-icons/bs'


const LocationComponent = ({ data }) => {

     const ovo = {
          fontFamily: 'Ovo',
          fontWeight: 400,
     }
     const karla = {
          fontFamily: 'Karla',
          fontWeight: 400
     }

     return (
          <SimpleGrid columns={[data.length % 2 === 0 ? 1 : 1, data.length % 2 === 0 ? 1 : 2, data.length % 2 === 0 ? 2 : 3, data.length % 2 === 0 ? 2 : 3, data.length % 2 === 0 ? 2 : 3]} gap={5}>
               {data?.map((x, i) => (
                    <Stack cursor={"pointer"}

                    _hover={{
                      transform: "scale(1.03)",
                      transition: "0.3s",
                    }} key={i} align={'center'}  shadow="md" p={3}>
                         <Image src={x.thumbnail} />
                         <Heading textTransform={'capitalize'} fontSize={'2xl'} style={ovo}>{x.name}</Heading>
                         <Text style={{ ...karla, fontWeight: 'bold' }} >{x.address}</Text>
                         <Text style={karla}>{x.location}</Text>
                         <Spacer />
                         <HStack >
                              <Text style={karla}>{x.day}:</Text>
                              <Text textTransform={'uppercase'} style={karla}>{x.hour}</Text>
                         </HStack>
                         {x.phone &&
                              <Text style={karla}>{x?.phone}</Text>
                         }
                         <HStack>
                              {x.socials.map((y, id) => (
                                   <a href={y.link} target='_blank' key={id} style={karla}>
                                        <IconButton
                                             size="lg"
                                             color='#0174BE'
                                             variant="ghost"
                                             aria-label="open menu"
                                             icon={y.type === 'ig' ? <BsInstagram size={20} /> : y.type === 'wa' ? <BsWhatsapp size={20} /> : y.type === 'maps' ? < BsGeoAltFill size={20} /> : <></>}

                                        />
                                        {x.socials.length === 1 && y.type === 'maps' && 'Get directions'}
                                   </a>

                              ))

                              }
                         </HStack>
                    </Stack>
               ))}
          </SimpleGrid>
     )
}

export default LocationComponent