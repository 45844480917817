// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

import song from "../Assets/Sound/CoinDrop-Notification.mp3";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9gO1SHKNskUWq31tgwbaPPdZRI73ZOLQ",
  authDomain: "project-klinik-b3ef5.firebaseapp.com",
  projectId: "project-klinik-b3ef5",
  storageBucket: "project-klinik-b3ef5.appspot.com",
  messagingSenderId: "317903216279",
  appId: "1:317903216279:web:85cffae8fdbc4dd41513d2",
  measurementId: "G-H2WWJWGZSQ",
  token_option:
    "BNerHDG7OUjsieYPKqPqnmlIUmO68xdnoUBVxtlaJ4PT02B9F2kYUr4N8b2UfvL51NSIFVIOoSA_vRGQxdggYSc",
};

// Initialize Firebase

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);
const configMessage = getMessaging(app);
auth.languageCode = "id";

export { app, analytics, auth, db, storage, database };
export const fetchToken = async (setTokenId) => {
  try {
    const token = await getToken(configMessage, {
      vapidKey: firebaseConfig.token_option,
    });
    if (token) {
      // console.log(token, "this is push notif token");
      setTokenId(token);
    } else {
      console.log("no push notif token for now");
    }
  } catch (error) {}
};

export const onMessageListener = (toast) => {
  onMessage(configMessage, (payload) => {
    const notif = new Audio(song);
    notif.play();
    const { data } = payload;
    const { title, description } = data;
    toast({
      title: title,
      description: description,
      position: "top-right",
      isClosable: true,
    });
  });
};
