import React, { useEffect, useState } from 'react'
import { CarouselComponent } from '../../Components/Gallery/CarouselComponent'
import { imgCarouselHead, imgCarouselMobile } from '../../Data/ImageCarousel'
import { Box, Button, Flex, HStack, Heading, Image, Input, SimpleGrid, Stack, Text, useMediaQuery, Spacer, AspectRatio, Center } from '@chakra-ui/react'
import { bannerCollections, bannerCollectionsMobile, dataCollections, lookbook, lookbookCollections, mediaPartner, sambasCollections, shopSambasCollections } from '../../Data/Collections'
import { PriceTag } from '../../Components/PriceTag/PriceTag'
import { useNavigate } from 'react-router-dom'
import { AiOutlineMail } from 'react-icons/ai'
import { dataUser } from '../../Data/DataUser'
import { getCollectionFirebase } from '../../Apis/firebaseApi'
import { BsBookmarkHeartFill } from 'react-icons/bs'
import logo from "../../Assets/Image/logoklinik.jpg"


const bgImage = 'https://www.dropbox.com/scl/fi/xe9fgzyagjyzk8g0bl6a7/1700227703482-2.-Pelayanan-Kami.png?rlkey=23a92lujy10sdfo863y9tq3ae&dl=0&raw=1'


const ovo = {
  fontFamily: 'Ovo',
  fontWeight: 400,
}
const karla = {
  fontFamily: 'Karla',
  fontWeight: 400
}
const themeColor = '#0174BE'

function HomePage() {
  const navigate = useNavigate()
  const [isDesktop, isMobile] = useMediaQuery(['(min-width: 1280px)', '(max-width: 414px)'])




  return (
    <Stack gap={0} mb={20}>
      {isMobile ?
        <CarouselComponent images={imgCarouselMobile} aspectRatio={2 / 3} />
        :
        <CarouselComponent images={imgCarouselHead} aspectRatio={16 / 9} />
      }
      <Stack gap={10}>
        <Box display={['block', 'block', 'flex', 'flex', 'flex', 'flex']} w='100%' justify={'center'} px={[0, 0, 0, 10, 0, 0]} align={['left', 'left', 'center', 'center', 'center', 'center']} h={['auto', 'auto', '400px', '400px']} my={[0, 0, 0, 0, 20, 20]} mt={[20, 20, 20, 20, 10, 10]}>
          <Flex direction={['column', 'column', 'row', 'row', 'row', 'row']} w='100%' align='center' justify={'center'}>
            <Flex w={['100%', '100%', '60%', '60%', '60%', '60%']} justify={['left', 'left', 'end', 'center', 'center', 'center']} align={['left', 'left', 'end', 'center', 'center', 'center']} mb={[20, 20, 0, 20, 0, 0]}>
              <Flex pos={'relative'} w={['100%', '100%', '80%', '80%', '80%', '80%']} justify={['end', 'center', 'end', 'end', 'end', 'end']}  >

                <Flex justify={['center', 'center', 'end', 'end', 'end', 'end']} px={[10, 0, 0, 0, 0, 0]}>
                  <Image aspectRatio={1} w={['60%', '60%', '70%', '70%', '70%', '70%']} objectFit={'contain'} src={logo} />
                </Flex>
              </Flex>
            </Flex>
            <Stack w={['100%', '100%', '40%', '40%', '40%', '40%']} mt={[0, 0, 20, 0, 0, 0]} pos={'relative'} left={['0', '0', '0', 0, 0, '-2%']} px={['10', '10', '10', '10', 10, '0']}>
              <Heading style={ovo} fontSize={'3xl'}>Tentang kami</Heading>
              <Text w={['100%', '100%', '100%', '100%', 300, 400]} style={karla}>Sejak tahun 2014, kami telah dengan penuh dedikasi menyediakan pelayanan perawatan maternal yang berkualitas dan berpengalaman. Dengan hampir 10 tahun perjalanan kami, kami bangga menjadi bagian dari momen-momen bersejarah keluarga, membantu ribuan kelahiran dengan keahlian dan kepedulian. Kami juga memiliki banyak program layanan yang membantu para ibu hamil</Text>
            </Stack>
          </Flex>
        </Box>



        <Stack bgColor={'#faf2e1'} py={20} gap={10} minH={300} justify={'center'} align={'center'} w='100%' px={[5, 10, 50, 79]}>
          <Stack justify={'center'} textAlign={'center'} w={'100%'}>
            <Flex align={'center'} justify={'center'}>
              <AiOutlineMail fontSize={30} />
            </Flex>
            <Heading style={ovo} fontSize={'3xl'}>Layanan Kami</Heading>
          </Stack>
          <Image src={bgImage} />

          {/* <Flex w={['100%', '80%', '60%', '30%']} justify={'center'} align={'center'}>
            <Input placeholder='Insert your email' bgColor={'white'} size={'lg'} rounded={0} value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button bgColor={themeColor} size={'lg'} border={'1px solid rgb(224,210,177)'} rounded={0} color={'white'}>Apply</Button>
          </Flex> */}
        </Stack>
      </Stack>

      <Stack p={[5, 10, 50, 79]} gap={[10, 10, 20, 20]} >
        <Heading style={ovo} fontSize={'3xl'} textAlign={'center'}>Fasilitas</Heading>
        <SimpleGrid columns={[1, 2, 4]} gap={[5, 5, 10, 10]}>
          {lookbook.map((x, i) => (
            <Image cursor={"pointer"}

              _hover={{
                transform: "scale(1.03)",
                transition: "0.3s",
              }} src={x.img} key={i} w={'full'} />
          ))}
        </SimpleGrid>
      </Stack>



      <Stack justify={'center'} textAlign={'center'} bgColor="blue.600" p={[1, 1, 5]} h={'auto'} bgRepeat={'no-repeat'} bgSize={'cover'}>
        {/* <Image h={700} w={'full'} src={bgImage}/> */}
        <Heading style={ovo} color={'white'} fontSize={['xl', '3xl', '5xl']}>Tips Seputar Kehamilan</Heading>
        <Text style={karla} color={'white'} fontSize={'xl'} letterSpacing={'0.2em'}> Tanda tanda akan segera melahirkan dalam waktu dekat</Text>
        <Center p={[1, 1, 5]}>
          <AspectRatio ratio={16 / 9} transition={'all 0.3s ease-in-out'} w="600px">
            <Box
              as="iframe"
              title="YouTube video player"
              src={("https://www.youtube.com/embed/tEbPkj2Hyi4?si=kCyr5o2J51I5wSIG")}
              allowFullScreen
              frameBorder="0"
            ></Box>
          </AspectRatio>
        </Center>
      </Stack>


    </Stack>

  )
}

export default HomePage