var date = new Date();

// add a day

export const dataCollections = [
  {
    id: 1,
    product_name: "Monogram Scarf London Edition Scarlet",
    product_price: 335000,
    product_category: "scarf",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC00815_eaf89558-1b1d-4b29-8ed3-190cf2f99423_360x.jpg?v=1696608181",
    createdAt: date.setDate(date.getDate() - 15),
    createdBy: "admin-1",
  },
  {
    id: 2,
    product_name: "Monogram Scarf London Edition Marble",
    product_price: 335000,
    product_category: "scarf",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC00655_ba75796c-09fb-4ef8-ab8b-a635e745e5b7_360x.jpg?v=1696607797",
    createdAt: date.setDate(date.getDate() - 14),
    createdBy: "admin-1",
  },
  {
    id: 3,
    product_name: "Alice Brooch Gold",
    product_price: 350000,
    product_category: "accessory",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/GoldI_360x.jpg?v=1696323665",
    createdAt: date.setDate(date.getDate() - 13),
    createdBy: "admin-1",
  },
  {
    id: 4,
    product_name: "Alice Brooch Silver",
    product_price: 350000,
    product_category: "accessory",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/SilverI_360x.jpg?v=1696323685",
    createdAt: date.setDate(date.getDate() - 12),
    createdBy: "admin-1",
  },
  {
    id: 5,
    product_name: "Alice Brooch Black",
    product_price: 350000,
    product_category: "accessory",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/BlackI_360x.jpg?v=1696323945",
    createdAt: date.setDate(date.getDate() - 10),
    createdBy: "admin-1",
  },
  {
    id: 6,
    product_name: "Paris Premium 2.0 Twill",
    product_price: 175000,
    product_category: "scarf",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC07939_c7e852f6-a044-41ad-8602-18dcc675304a_360x.jpg?v=1696047592",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
    discount: 10,
  },
  {
    id: 7,
    product_name: "Paris Premium 2.0 Tiramisu",
    product_price: 175000,
    product_category: "scarf",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC08049_a3642f36-8c4e-4d04-8c69-b6ee3628a880_360x.jpg?v=1696047627",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
    discount: 10,
  },
  {
    id: 8,
    product_name: "Marella Abaya Outer Graphite Gray",
    product_price: 645000,
    product_category: "outer",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC07630_360x.jpg?v=1695801602",
    product_size: [
      { size: "xs/s", stock: "avail" },
      { size: "m/l", stock: "avail" },
      { size: "xl/xxl", stock: "avail" },
    ],
    size_guide:
      "https://klamby.id/cdn/shop/files/Marella-abaya-size-chart_765x.jpg?v=1695797142",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
  },
  {
    id: 9,
    product_name: "Marella Abaya Outer Dusty Rouge",
    product_price: 645000,
    product_category: "outer",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC07770_a434f54c-b10a-4c3c-b35d-7f6c5b280ee1_360x.jpg?v=1695800833",
    product_size: [
      { size: "xs/s", stock: "avail" },
      { size: "m/l", stock: "avail" },
      { size: "xl/xxl", stock: "avail" },
    ],
    size_guide:
      "https://klamby.id/cdn/shop/files/Marella-abaya-size-chart_765x.jpg?v=1695797142",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
  },
  {
    id: 10,
    product_name: "Victoria Scarf Bellflower",
    product_price: 335000,
    product_category: "scarf",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/DSC06662_ee274c85-7d84-4f3c-ad9a-078fd1218470_540x.jpg?v=1695452274",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
    discount: 20,
  },
  {
    id: 11,
    product_name: "Perfume Travel Size Kit",
    product_price: 450000,
    product_category: "cosmetic",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/untitled-05707_360x.jpg?v=1695452868",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
  },
  {
    id: 12,
    product_name: "Sambas Long Shirt Men Silverlight",
    product_price: 395000,
    product_category: "shirt",
    product_gender: "men",
    product_size: [
      { size: "xs", stock: "avail" },
      { size: "s", stock: "sold" },
      { size: "m", stock: "avail" },
      { size: "l", stock: "avail" },
      { size: "xl", stock: "avail" },
      { size: "xxl", stock: "avail" },
    ],
    size_guide:
      "https://klamby.id/cdn/shop/files/Sambas-long-shirt-men_765x.jpg?v=1693214381",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02857_360x.jpg?v=1693626593",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
  },
  {
    id: 13,
    product_name: "Sambas Skirt Nightfall",
    product_price: 450000,
    product_category: "skirt",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02823_360x.jpg?v=1693476977",
    size_guide:
      "https://klamby.id/cdn/shop/files/Sambas-Skirt_765x.jpg?v=1693213392",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
  },
  {
    id: 14,
    product_name: "Sambas Skirt Gleam Beam",
    product_price: 450000,
    product_category: "skirt",
    product_gender: "woman",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02813_360x.jpg?v=1693476958",
    size_guide:
      "https://klamby.id/cdn/shop/files/Sambas-Skirt_765x.jpg?v=1693213392",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
  },
  {
    id: 15,
    product_name: "Sambas Long Shirt Men Nightfall",
    product_price: 395000,
    product_category: "shirt",
    product_gender: "men",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02837_360x.jpg?v=1693626587",
    product_size: [
      { size: "xs", stock: "sold" },
      { size: "s", stock: "sold" },
      { size: "m", stock: "sold" },
      { size: "l", stock: "sold" },
      { size: "xl", stock: "sold" },
      { size: "xxl", stock: "sold" },
    ],
    size_guide:
      "https://klamby.id/cdn/shop/files/Sambas-long-shirt-men_765x.jpg?v=1693214381",
    createdAt: date.setDate(date.getDate() - 1),
    createdBy: "admin-1",
    quantity: "sold",
  },
];

export const dataCheckout = [
  {
    id: 6,
    name: "Paris Premium 2.0 Twill",
    price: 175000,
    category: "scarf",
    gender: "woman",
    thumbnail:
      "https://klamby.id/cdn/shop/files/DSC07939_c7e852f6-a044-41ad-8602-18dcc675304a_360x.jpg?v=1696047592",
    createdAt: date,
    createdBy: "user-1",
    discount: 10,
    quantity: 2,
  },
  {
    id: 9,
    name: "Marella Abaya Outer Dusty Rouge",
    price: 645000,
    category: "outer",
    gender: "woman",
    thumbnail:
      "https://klamby.id/cdn/shop/files/DSC07770_a434f54c-b10a-4c3c-b35d-7f6c5b280ee1_360x.jpg?v=1695800833",
    size: "xl/xxl",
    quantity: 1,
    createdAt: date,
    createdBy: "user-1",
  },
  {
    id: 3,
    name: "Alice Brooch Gold",
    price: 350000,
    category: "accessory",
    gender: "woman",
    thumbnail: "https://klamby.id/cdn/shop/files/GoldI_360x.jpg?v=1696323665",
    quantity: 3,
    createdAt: date,
    createdBy: "user-1",
  },
];

export const sambasCollections = [
  {
    id: 1,
    img: "https://klamby.id/cdn/shop/files/Frame_1997_540x.jpg?v=1693617501",
  },
  {
    id: 2,
    img: "https://klamby.id/cdn/shop/files/Frame_1996_540x.jpg?v=1693617530",
  },
  {
    id: 3,
    img: "https://klamby.id/cdn/shop/files/Frame_1996_540x.jpg?v=1693617530",
  },
];

export const shopSambasCollections = [
  {
    id: 1,
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02767_360x.jpg?v=1693476257",
    product_name: "Sambas Tunic Silverlight",
    product_price: 625000,
  },
  {
    id: 2,
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02790_360x.jpg?v=1693476240",
    product_name: "Sambas Tunic Pale Blossom",
    product_price: 625000,
  },
  {
    id: 3,
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02666_360x.jpg?v=1693476225",
    product_name: "Sambas Tunic Nightfall",
    product_price: 625000,
  },
  {
    id: 4,
    product_name: "Sambas Tunic Gleam Beam",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02775_360x.jpg?v=1693476199",
    product_price: 625000,
  },
  {
    id: 4,
    product_name: "Sambas Tunic Gleam Beam",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02775_360x.jpg?v=1693476199",
    product_price: 625000,
  },
  {
    id: 4,
    product_name: "Sambas Tunic Gleam Beam",
    product_thumbnail:
      "https://klamby.id/cdn/shop/files/TAP02775_360x.jpg?v=1693476199",
    product_price: 625000,
  },
];

export const bannerCollections = [
  {
    id: 1,
    image:
      "https://klamby.id/cdn/shop/files/Banner_desktop_1728x.jpg?v=1696304785",
    alt: "alice-brooch",
  },
  {
    id: 2,
    image:
      "https://klamby.id/cdn/shop/files/Paris_Premium_Vol.2_-_Banner_f9aea2b2-45f9-44a2-85ed-65a577512780_1728x.jpg?v=1696174575",
    alt: "paris-premium-scarf-2.0",
  },
  {
    id: 3,
    image:
      "https://klamby.id/cdn/shop/files/Marella_Abaya_Banner_-_Desktop_1728x.jpg?v=1695790161",
    alt: "marella-abaya",
  },
  {
    id: 4,
    image:
      "https://klamby.id/cdn/shop/files/Aerish_Abaya_Outer_-_Mobile_1512x.jpg?v=1697091920",
    alt: "aerish-abaya-outer",
  },
];

export const bannerCollectionsMobile = [
  {
    id: 1,
    image:
      "https://klamby.id/cdn/shop/files/Banner_Mobile_64bdf205-5799-43be-96af-dc8d23c08af2_1512x.jpg?v=1696304804",
    alt: "alice-brooch",
  },
  {
    id: 2,
    image:
      "https://klamby.id/cdn/shop/files/Paris_Premium_Vol.2_-_Banner_Mobile_430ab83b-19db-439b-9ba5-6f29f4d720bc_1296x.jpg?v=1696174596",
    alt: "paris-premium-scarf-2.0",
  },
  {
    id: 3,
    image:
      "https://klamby.id/cdn/shop/files/Marella_Abaya_Banner_-_Mobile_1296x.jpg?v=1695790177",
    alt: "marella-abaya",
  },
  {
    id: 4,
    image:
      "https://klamby.id/cdn/shop/files/Aerish_Abaya_Outer_-_Dekstop_1728x.jpg?v=1697091898",
    alt: "aerish-abaya-outer",
  },
];

export const lookbookCollections = [
  {
    id: 1,
    img: "https://klamby.id/cdn/shop/files/Marella_Abaya_Cover_9cbab640-a314-45fe-9441-5ebe3d5afddb_720x.jpg?v=1697087061",
  },
  {
    id: 2,
    img: "https://klamby.id/cdn/shop/files/Cover_monogram_london_720x.jpg?v=1696602526",
  },
  {
    id: 3,
    img: "https://klamby.id/cdn/shop/files/Cover_alice_brooch_540x.jpg?v=1696304716",
  },
  {
    id: 4,
    img: "https://klamby.id/cdn/shop/files/Cover_paris_premium_vol.2_6e820b4c-7007-485a-bbb5-e8aa88977f97_540x.jpg?v=1696175046",
  },
  {
    id: 5,
    img: "https://klamby.id/cdn/shop/files/Marella_Abaya_Cover_540x.jpg?v=1695790085",
  },
];

export const mediaPartner = [
  {
    id: 1,
    img: "https://klamby.id/cdn/shop/files/highend_180x.png?v=1662113057",
    alt: "highend",
  },
  {
    id: 2,
    img: "https://klamby.id/cdn/shop/files/tatler_180x.png?v=1662112074",
    alt: "tatler",
  },
  {
    id: 3,
    img: "https://klamby.id/cdn/shop/files/fimela_logo_180x.png?v=1660015909",
    alt: "fimela",
  },
  {
    id: 4,
    img: "https://klamby.id/cdn/shop/files/fortune_logo_180x.jpg?v=1660015984",
    alt: "fortune-indonesia",
  },
  {
    id: 5,
    img: "https://klamby.id/cdn/shop/files/Bazaar_180x.jpg?v=1653285952",
    alt: "bazaar-indonesia",
  },
  {
    id: 6,
    img: "https://klamby.id/cdn/shop/files/Cosmopolitan_180x.jpg?v=1653286136",
    alt: "cosmopolitan-indonesia",
  },
  {
    id: 7,
    img: "https://klamby.id/cdn/shop/files/IDN_Media_180x.jpg?v=1653286180",
    alt: "idn-media",
  },
];

export const lookbook = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.33.jpeg?alt=media&token=c9957b9c-df33-40ee-999b-e45b6b2d666d",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.32.jpeg?alt=media&token=0b813969-c29f-4c35-bf1c-c0a6e1657dec",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.32%20(1).jpeg?alt=media&token=e18833f1-57ad-4f76-91c2-a1fcecc74752",
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.31.jpeg?alt=media&token=c60c87d9-a93e-4068-a2aa-4c37fc231182",
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.31%20(1).jpeg?alt=media&token=8e8301a1-7a68-4411-ab63-d21d82e6882e",
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.30.jpeg?alt=media&token=5d8337d1-730d-4f95-a3d5-bfe21e2e73b8",
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.30%20(2).jpeg?alt=media&token=84cd35d7-6455-4565-b940-b83a5c7b2379",
  },
  {
    id: 8,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.30%20(1).jpeg?alt=media&token=8f52157f-8986-4752-830f-2459983b7090",
  },
  {
    id: 9,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.29.jpeg?alt=media&token=111879ad-3498-4ccc-8972-eef47a7a614b",
  },
  {
    id: 10,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.29%20(1).jpeg?alt=media&token=03126081-c0a6-4423-b4f3-7884707dcc42",
  },
  {
    id: 11,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.28.jpeg?alt=media&token=d3b95c57-a868-41fe-96bc-f095b68ebe68",
  },
  {
    id: 12,
    img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.28%20(2).jpeg?alt=media&token=512f40a4-8bf3-436f-ab0e-ccc8a96cee20",
  },
  // {
  //   id: 13,
  //   img: "https://firebasestorage.googleapis.com/v0/b/project-klinik-b3ef5.appspot.com/o/fasilitas%2FWhatsApp%20Image%202023-11-11%20at%2020.23.28%20(1).jpeg?alt=media&token=eff41019-6a10-4c7d-9834-813c5633a1c8",
  // },
];
