import { Box, Button, HStack, Heading, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react'
import React from 'react'
import { dataUser, dataUserAddress } from '../../Data/DataUser'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../../Config/firebase'
import store from "store"
import { UseAuthStore } from '../../Hooks/Zustand/store'

// Styling
const ovo = {
     fontFamily: 'Ovo',
     fontWeight: 400,
}
const karla = {
     fontFamily: 'Karla',
     fontWeight: 400
}



const SettingPage = () => {
     const navigate = useNavigate()

     const toast = useToast()

     const globalState = UseAuthStore();


     const handleLogout = () => {
      
          signOut(auth)
            .then(() => {
              // Sign-out successful.
              toast({
                status: "success",
                description: "Logged out success",
                duration: 2000,
              });
              navigate("/");
              store.clearAll();
              globalState.setIsLoading(false);
              window.location.reload();
            })
            .catch((error) => {
              console.log(error, "ini error");
            });
     }

     return (
          <Stack textAlign={'left'} gap={5} mb={10}>
               <Heading textAlign={'center'} style={ovo} fontSize={'3xl'}>My account</Heading>
               <Button variant={'link'} color={'black'} style={karla} textDecor={'underline'} onClick={handleLogout}>Log out</Button>

               <SimpleGrid columns={[1, 1, 1, 2]} templateColumns={['100%', '100%', '100%', '60% 40%']}>
                    <Stack gap={5}>
                         <Heading style={ovo} fontSize={'3xl'}>Order History</Heading>
                         <Text style={karla}>You haven't placed any orders yet.</Text>
                    </Stack>
                    <Stack gap={5} align={'left'} justify={'left'} textAlign={'left'} mt={[10,10,10,0]}>
                         <Heading style={ovo} fontSize={'3xl'}>Account Details</Heading>
                         <Text style={ovo}>{`${dataUser?.firstName} ${dataUser?.lastName}`}</Text>
                         <Box>
                              <Text style={karla}>{`${dataUser?.firstName} ${dataUser?.lastName}`}</Text>
                              <Text style={karla}>{dataUser?.company}</Text>
                              <Text style={karla}>{dataUser?.address1}</Text>
                              <Text style={karla}>{dataUser?.address2}</Text>
                              <Text style={karla}>{dataUser?.city}</Text>
                              <HStack>
                                   <Text style={karla}>{dataUser?.province}</Text>
                                   <Text style={karla}>{dataUser?.postal}</Text>
                              </HStack>
                              <Text style={karla}>{dataUser?.country}</Text>
                         </Box>
                         <Button alignItems={'left'} justifyContent={'left'} variant={'link'} color={'black'} style={karla} textAlign={'left'} onClick={()=>navigate('/address')} textDecor={'underline'}>View addresses ({dataUserAddress.addresses.length})</Button>
                    </Stack>
               </SimpleGrid>
          </Stack>
     )
}

export default SettingPage