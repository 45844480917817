import { Button, Center, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Gallery } from './Gallery'

export const CarouselComponent = (props) => {

	const navigate = useNavigate()

	const ovo = {
		fontFamily: 'Ovo',
		fontWeight: 400,
	}
	const karla = {
		fontFamily: 'Karla',
		fontWeight: 400
	}

	let images = []
	if (!props.images)
		images = [
			{
				id: '01',
				image: 'https://www.pngmart.com/files/22/Gray-Background-PNG-Isolated-File.png',
				alt: ' ',
			},
			{
				id: '02',
				image: 'https://www.pngmart.com/files/22/Gray-Background-PNG-Isolated-File.png',
				alt: ' ',
			},
			{
				id: '03',
				image: 'https://www.pngmart.com/files/22/Gray-Background-PNG-Isolated-File.png',
				alt: ' ',
			},

		]
	else
		images = props.images

	return (
		<Stack>
			<Stack position={"relative"}>
				<Gallery images={images} aspectRatio={props.aspectRatio} />
			</Stack>

			<Center position={"absolute"} >

				<Stack bgColor={'rgba(1,1,1,0.4)'} justifyContent={'center'} textAlign={'center'} h="300px" w={"80%"} alignItems="center" px={[5, 10, 20, 20]} mt={[0, 0, 20, 0, 0, 0]}>
					<Heading style={ovo} color={'white'} fontSize={['lg', '3xl', '5xl', '4xl']}>Melahirkan Kesehatan, Merayakan Kehadiran Baru</Heading>
					<Text style={karla} color={'white'} fontSize={['sm', 'lg', '2xl', 'xl']} letterSpacing={'0.2em'}> Kami Hadir untuk Kesehatan Anda Dimana Kesehatan Ibu dan Anak Menjadi Fokus yang Utama. Saatnya wujudkan pelayanan kesehatan yang menyenangkan untuk persalinan ibu dan buah hati.</Text>
					<Flex justify={'center'} mt={5}>
						<Button onClick={() => navigate("/layanan")} style={ovo} color={'white'} variant={'outline'} rounded={0} size={['sm', 'md', 'lg']} w='fit-content' _hover={{ color: 'black', bgColor: 'white' }}>INFO SELENGKAPNYA</Button>
					</Flex>
				</Stack>
			</Center>
		</Stack>
	)
}