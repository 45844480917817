import { AspectRatio, Box, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase } from '../../Apis/firebaseApi';

function EdukasiPage() {

    const [courseActive, setCourseActive] = useState("");
    const [academyDetail, setAcademyDetail] = useState('')
    const [courses, setCourses] = useState([]);


    const getDataCourse = async () => {
        const conditions = [
            { field: "active", operator: "==", value: true },
        ];
        const sortBy = { field: "createdAt", direction: "desc" };
        const limitValue = 10;

        try {
            const res = await getCollectionFirebase(
                "course",
                conditions,
                sortBy,
                limitValue
            );
            setCourses(res)
            setCourseActive(res[0])

        } catch (error) {
            console.log(error, "ini error");
        }
    };

    useEffect(() => {
        getDataCourse();
        return () => {

            setCourses([])
            setCourseActive("")
            setAcademyDetail("")
        };
    }, []);

    const handleActive = (x) => {
        setCourseActive(x);
    };


    return (
        <Box w="100%" h="100%" p={[1, 2, 5]} bgColor="gray.100">
            <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={4}>
                <Stack spacing={3}>
                    <Box transition={'all 0.3s ease-in-out'}>
                        {courseActive && (
                            <AspectRatio ratio={16 / 9} transition={'all 0.3s ease-in-out'}>
                                <Box
                                    as="iframe"
                                    title="YouTube video player"
                                    src={(courseActive.link)}
                                    allowFullScreen
                                    frameBorder="0"
                                ></Box>
                            </AspectRatio>
                        )}
                    </Box>
                    <Stack spacing={3} bgColor="white" p={5} borderRadius="md" shadow="lg">
                        <Heading size="md">
                            {academyDetail?.title}
                        </Heading>
                        <Heading size="xs">
                            {courseActive ? courseActive.title : ''}
                        </Heading>
                        <Stack>
                            <Text>{courseActive.description}</Text>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={1} bgColor="white" p={2} borderRadius="md" shadow="lg" h={'800px'} overflowY={'scroll'}>
                    <Text fontWeight="bold" fontSize="xl" color="gray.600">
                        Other Education
                    </Text>
                    <Stack spacing={1}>
                        {courses?.length > 0 ? (
                            courses?.map((course, index) => (
                                <Stack
                                    key={index}
                                    _hover={{ transform: "scale(1.05)" }}
                                    transition="0.2s ease-in-out"
                                    onClick={() => handleActive(course)}
                                    cursor="pointer"
                                    borderWidth={1}
                                    py={2}
                                    px={3}
                                    borderRadius="xl"
                                    borderColor={courseActive.title === course.title ? "blue.300" : "transparent"}
                                >
                                    <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={3} alignItems="center" justifyContent="center">
                                        <AspectRatio ratio={1} transition={'all 0.3s ease-in-out'}>
                                            <Box
                                                as="iframe"
                                                title="YouTube video player"
                                                src={`${course.link}?autoplay=0`}
                                                allowFullScreen
                                                frameBorder="0"
                                            ></Box>
                                        </AspectRatio>
                                        <Box>
                                            <Text fontSize="sm" fontWeight="bold">
                                                {course.title}

                                            </Text>
                                            <Text fontSize="xs">{moment(course?.createdAt?.seconds * 1000).format('LLL')}</Text>
                                        </Box>
                                    </Grid>
                                </Stack>
                            ))
                        ) : (
                            <Text>
                                Video Not Found
                            </Text>
                        )}
                    </Stack>
                </Stack>
            </Grid>
        </Box>
    )
}

export default EdukasiPage