import React from "react";
import LoginPage from "../Pages/Authentication/LoginPage";
import SignUpPage from "../Pages/Authentication/SignUpPage";

const AuthenticationRouter = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage/>,
  },


];

export default AuthenticationRouter;
