import { Button, HStack, Input, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiSearchAlt2 } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { UseAuthStore } from '../../Hooks/Zustand/store';

function AppSearchInput() {

    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const toast = useToast();

    const globalState = UseAuthStore();

    const [loading, setLoading] = useState(false)




    const handleKeyDown = async (event) => {

        if (event.key === 'Enter') {
            setLoading(true)

            if (search === undefined || search === "") {
                return toast({
                    title: 'DapetKomisi',
                    description: 'Please type in your search again',
                    status: 'warning',
                });
            }

            navigate(`/search?detail=${search}`, { state: search });
            setLoading(false)

        }
    };

    const handleSearch = async () => {
        setLoading(true)
        if (search === undefined || search === "") {
            setLoading(false)

            return toast({
                title: 'DapetKomisi',
                description: 'Please type in your search again',
                status: 'warning',
            });
        }
        navigate(`/search?detail=${search}`, { state: search });
        setLoading(false)
    }




    return (
        <Stack flexDirection={['row', 'row', 'row']} w='80%' alignItems='center' justifyContent={'center'}>
            <BiSearchAlt2 size={30} />
            <Input
                borderRadius='2xl'
                bgColor={'white'}
                size={'md'}
                placeholder='Search our klink...'
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
            />
        </Stack>
    )
}

export default AppSearchInput